import tw from "twrnc";
import { isWeb } from "../utils/platform";

const isTestMode = false;

export const VERTICALS = {
  fritzy: "fritzy",
  pizzy: "pizzy",
};

export const logos = {
  fritzy: require("../../assets/icon.png"),
  pizzy: require("../../assets/icon-pizzy.png"),
};

const forceVertical = null;
export const VERTICAL = !isWeb
  ? VERTICALS.fritzy
  : forceVertical ||
    (window?.location?.hostname?.includes("pizzy")
      ? VERTICALS.pizzy
      : VERTICALS.fritzy);

const settings = {
  time_before_order: 10,
  name: VERTICAL === VERTICALS.pizzy ? "Pizzy" : "Fritzy",
  domain: VERTICAL === VERTICALS.pizzy ? "pizzy.be" : "fritzy.be",
  days: [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ],
  version: "V2.0.4",
  colors:
    VERTICAL === VERTICALS.pizzy
      ? {
          yellow_main: "#88AA49",

          background: "#f1ebe6",
          background_dark: "#2E4332",

          gray_dark: "#6B6B6B",
          gray_light: "#D1D1D1",

          green: "#5BBA70",
          orange: "#ffac1c",
          red: "#C73D1F",
        }
      : {
          yellow_main: "#fbb336",

          background: "#f1ebe6",
          background_dark: "#232323",

          gray_dark: "#6B6B6B",
          gray_light: "#D1D1D1",

          green: "#5BBA70",
          orange: "#ffac1c",
          red: "#C4331E",
        },

  default_location: [4.3630389, 50.8463859],

  stripe: {
    pk: isTestMode
      ? "pk_test_51MLwVXKZQJ5MXgb9h8aPj4wyvPe7lRJVY2R2zDTxxPdm3fwVcJYPuIkbQEh8vTYCOSdF74Nh5WCYBr4Kcopft57J00puXNSZG1"
      : "pk_live_51MLwVXKZQJ5MXgb9t83wcpdLbD7ef5s3Y0BaHUrmnv6zB5bgp3jYZuRamXw69q26XSyUb7TxAAnQPX712OdVt3HI00QiszDEiL",
  },

  mapBoxKey:
    "pk.eyJ1IjoibWF4aW1lMjQiLCJhIjoiY2xnam8ybTZzMDU4YjNncXN5MWZzOHM2NiJ9.hCP9byeGPPhRm12fCWduUA",
};

export const styles = {
  header_3xl: tw`font-medium text-3xl text-black`,
  header_2xl: tw`font-medium text-2xl text-black`,
  header_xl: tw`font-medium text-xl text-black`,
  header_xl_normal: tw`text-xl text-black`,
  header_lg: tw`font-medium text-lg text-black`,
  header_lg_normal: tw`text-lg text-black`,
  header_me: tw`font-medium text-base text-black`,
  header_light: tw`font-light text-lg text-black`,
  header_extralight: tw`font-extralight text-lg text-black`,

  desc_s: tw`font-light text-base text-gray-500`,
  desc_xs: tw`font-extralight text-gray-500`,
  desc: tw`text-gray-500`,

  link: tw`text-gray-500 font-light text-base underline`,

  yellow_lg: {
    ...tw`font-medium uppercase text-base`,
    color: settings.colors.yellow_main,
  },
  light_lg: tw`font-light text-lg`,

  error_me: tw`font-light text-base text-red-700`,
  success_me: tw`font-light text-base text-green-600`,
  neutral_me: tw`font-light text-base`,
};

export default settings;
