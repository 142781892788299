import Icon from "@expo/vector-icons/Entypo";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import Mapper from "../../components/Mapper";
import RadioButton from "../../components/RadioButton";
import { TransProp } from "../../components/Trans";
import YellowLine from "../../components/YellowLine";
import { PlatformContext } from "../../contexts";
import { hasTrans } from "../../utils/others";
import {
  getOptionTimes,
  getProductPrice,
  getSelectedOption,
} from "../../utils/products";
import { styles } from "../../utils/settings";
import Checkbox from "../Checkbox";

const Options = ({ product, extras, setExtras }) => {
  const platform = useContext(PlatformContext);
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState([]);

  const onOption = (option, selection, operation = "+") => {
    const copy = [...extras];
    if (option.type === 1) {
      let x = copy.find((x) => x.option_id === option.id);
      if (!x) copy.push({ option_id: option.id, selection, qty: 1 });
      else x.selection = selection;
    } else {
      let x = copy.find(
        (x) => x.option_id === option.id && x.selection.id === selection.id
      );
      if (!x && operation !== "+") return;
      else if (!x) copy.push({ option_id: option.id, selection, qty: 1 });
      else if (operation === "+" && selection.max && x.qty >= selection.max)
        return;
      else x.qty = operation === "+" ? x.qty + 1 : x.qty - 1;
    }

    setExtras(copy.filter((x) => x.qty > 0));
  };

  return (
    <Mapper
      loading={false}
      data={product?.options
        ?.filter((x) => x && x.active)
        .sort((a, b) => a.sort - b.sort)}
      render={(o) => {
        const options = o.options?.filter((x) => x && x.active);
        const collapsable =
          !showAll.includes(o.id) && o.shorten && options.length > 5;

        const isDisabled = (po) => {
          if (o.type === 2) {
            const isSelected = getSelectedOption(extras, o, po.id) >= 1;
            if (isSelected) return false;

            return getOptionTimes(extras, o) >= (o.max || 9999);
          }
          return false;
        };

        const onPress = (po) => {
          if (isDisabled(po)) return false;

          if (o.type === 1) onOption(o, po);
          if (o.type === 2)
            onOption(
              o,
              po,
              getSelectedOption(extras, o, po.id) === 0 ? "+" : "-"
            );
        };

        return (
          <View key={`option_${o.id}`}>
            <View style={tw`flex-row justify-between items-center mb-4`}>
              <View>
                <Text style={styles.header_xl}>
                  <TransProp v={o.name} />
                </Text>
                {hasTrans(o.description) && (
                  <Text style={{ ...tw`mt-0.5`, ...styles.desc_s }}>
                    <TransProp v={o.description} />
                  </Text>
                )}
              </View>
              {o.required && (
                <Text style={{ color: platform.colors.red }}>
                  {t("product.required")}
                </Text>
              )}
            </View>
            <Mapper
              loading={false}
              data={options.filter(
                (o, i) => !collapsable || showAll.includes(o.id) || i < 5
              )}
              render={(po, pi) => (
                <View
                  key={`option_choice_${pi}`}
                  style={tw`mb-4 flex-row justify-between items-center ${
                    isDisabled(po) ? "opacity-30" : ""
                  }`}
                >
                  <TouchableOpacity
                    style={tw`flex-grow`}
                    disabled={isDisabled(po) || o.type === 3}
                    onPress={() => onPress(po)}
                  >
                    <View style={tw`flex-row items-center`}>
                      <Text style={{ ...styles.header_lg_normal }}>
                        <TransProp v={po.name} />
                      </Text>
                      {hasTrans(po.description) && (
                        <Text style={{ ...styles.desc_xs, ...tw`mt-0.5 ml-2` }}>
                          <TransProp v={po.description} />
                        </Text>
                      )}
                    </View>
                    {po.price?.regular && (
                      <Text style={tw`font-light text-sm`}>
                        {getProductPrice(po).toFixed(2)}€
                        {getSelectedOption(extras, o, po.id) > 0
                          ? ` x ${getSelectedOption(extras, o, po.id)}`
                          : ""}
                      </Text>
                    )}
                  </TouchableOpacity>
                  {o.type === 1 && (
                    <RadioButton
                      selected={getSelectedOption(extras, o, po.id) > 0}
                      onPress={() => onPress(po)}
                    />
                  )}
                  {o.type === 2 && (
                    <Checkbox
                      onChange={() => onPress(po)}
                      checked={getSelectedOption(extras, o, po.id) === 1}
                    />
                  )}
                  {o.type === 3 && (
                    <View style={tw`flex-row items-center mr--3`}>
                      <TouchableOpacity
                        style={tw`p-3`}
                        onPress={() => onOption(o, po, "-")}
                      >
                        <Icon name="minus" size={20} />
                      </TouchableOpacity>
                      <Text style={tw`w-10 text-center`}>
                        {getSelectedOption(extras, o, po.id)}
                      </Text>
                      <TouchableOpacity
                        style={tw`p-3`}
                        onPress={() => onOption(o, po, "+")}
                      >
                        <Icon name="plus" size={20} />
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              )}
            />
            {collapsable && !showAll.includes(o.id) ? (
              <TouchableOpacity
                style={{
                  ...tw`py-5 flex-col items-center justify-center rounded-xl`,
                  backgroundColor: platform.isPizzy ? "#cfddb6" : "#FEF8ED",
                }}
                onPress={() => setShowAll([...showAll, o.id])}
              >
                <Text style={tw`text-center font-medium underline`}>
                  {t("word.show_all")}
                </Text>
              </TouchableOpacity>
            ) : null}
            <YellowLine style={tw`mt-2 mb-7`} />
          </View>
        );
      }}
    />
  );
};

export default Options;
