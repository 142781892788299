import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ImageBackground,
  ScrollView,
  StatusBar,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import tw from "twrnc";
import ActionButton from "../../../components/ActionButton";
import BigButton from "../../../components/BigButton";
import Checkbox from "../../../components/Checkbox";
import HalfModal from "../../../components/HalfModal";
import RadioButton from "../../../components/RadioButton";
import SimpleHeader from "../../../components/SimpleHeader";
import SimpleSearch from "../../../components/SimpleSearch";
import { TransProp } from "../../../components/Trans";
import YellowLine from "../../../components/YellowLine";
import { PlatformContext, UserContext } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import {
  getFile,
  getRestaurantCategories,
  getRestaurantOptions,
  getTemplateImages,
  uploadImage,
  upsertRestaurantProduct,
} from "../../../utils/db";
import { pickImageAsync } from "../../../utils/media";
import { hasTrans, webBack } from "../../../utils/others";
import { isDesktop, isIos, isWeb } from "../../../utils/platform";
import { formatPrice, getProductPrice } from "../../../utils/products";
import { styles } from "../../../utils/settings";
import Screen from "../../Screen";
import {
  InputBase,
  InputContainer,
  LangField,
} from "../CategoriesModule/AdminCategoriesModule";

export const EditButton = ({
  size = 15,
  label,
  e: onPress,
  icon = "pen",
  disabled,
  style,
}) => {
  const platform = useContext(PlatformContext);
  const { t } = useTranslation();

  return (
    <BigButton
      type="yellow-outline"
      onPress={onPress}
      style={{
        ...tw`flex-row items-center justify-center py-1 px-3 bg-white`,
        ...style,
      }}
      disabled={disabled}
    >
      <FontAwesome5
        style={tw`mr-3`}
        name={icon}
        size={size}
        color={platform.colors.yellow_main}
      />
      {"   "}
      <Text style={tw`ml-2 text-[${platform.colors.yellow_main}]`}>
        {label ? label : t("word.edit")}
      </Text>
    </BigButton>
  );
};

const EditRow = ({ style, e: onPress, icon, size, children }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        ...tw`flex-row items-center justify-between flex-wrap`,
        ...style,
      }}
    >
      <View style={tw`mr-3 mb-5`}>{children}</View>
      <EditButton e={onPress} icon={icon} size={size} />
    </TouchableOpacity>
  );
};

export const OptionCard = ({ o, children, onPress, style }) => {
  const { lang } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <TouchableOpacity
      disabled={isWeb ? false : !onPress}
      onPress={onPress}
      style={{
        ...tw`mb-5 flex-row items-center justify-between w-full`,
        ...style,
      }}
    >
      <View style={tw`mr-3 flex-1`}>
        <Text
          style={{
            ...styles.header_lg,
            ...tw`${!o.active ? "line-through" : ""}`,
          }}
        >
          <TransProp v={o.name} />
        </Text>
        {hasTrans(o.description) && (
          <Text style={styles.desc_s} numberOfLines={1}>
            <TransProp v={o.description} />
          </Text>
        )}
        {o.options?.length && (
          <Text style={styles.desc_s} numberOfLines={1}>
            {t("word.choices")}:{" "}
            {o.options?.map((op) => `${op.name[lang]}`).join(", ")}
          </Text>
        )}
        {o.price && (
          <Text style={styles.desc_s} numberOfLines={1}>
            {o.price.discounted > 0 && (
              <>
                <Text style={{ ...tw`line-through`, ...styles.desc_xs }}>
                  {parseFloat(o.price.regular || 0).toFixed(2)}€
                </Text>
                {"  "}
              </>
            )}
            {getProductPrice(o).toFixed(2)}€
          </Text>
        )}
      </View>
      {children}
    </TouchableOpacity>
  );
};

const AdminProductEditModule = () => {
  const platform = useContext(PlatformContext);
  const { goBack } = useNavigation();
  const { t } = useTranslation();
  const {
    params: { restaurant, product: initProduct, selected },
  } = useRoute();

  const { data: categories } = useApi(getRestaurantCategories, {
    restaurant,
  });
  const { data: options } = useApi(getRestaurantOptions, {
    restaurant,
  });

  const [product, setProduct] = useState(initProduct);
  const [prop, setProp] = useState(null);
  const [imgLibrary, setImgLibrary] = useState(false);
  const [saving, setSaving] = useState(false);

  const onOption = (option, isRemove = false) => {
    const copy = [...(product.options || [])];
    const o = copy.find((x) => x.id === option.id);

    if (!o) {
      copy.push({ ...option, _added: true });
    } else {
      o._added = !isRemove;
      o._deleted = isRemove;
    }

    setProduct((p) => ({ ...p, options: copy }));
  };

  const onSaveProp = async (newValue) => {
    setSaving(true);

    if (prop.type === "price") {
      newValue.regular = formatPrice(newValue.regular);
      newValue.discounted = formatPrice(newValue.discounted);
    }

    if (prop.type === "option") {
      onOption(newValue);
    } else {
      setProduct((p) => ({ ...p, [prop.field]: newValue }));
    }

    setProp(null);
    setSaving(false);
  };

  const onSubmit = async (extra) => {
    setSaving(true);

    let copy = { ...product, ...extra };

    copy.category = copy.category.id;
    if (copy.category === -1) copy.category = null;

    copy.price_regular = copy.price.regular;
    copy.price_discounted = copy.price.discounted;

    if (!copy.id) copy.sort = new Date().getTime();

    if (Object.keys(copy.description || {}).length === 0)
      copy.description = null;

    if (copy.new_image) {
      const { data: result } = await uploadImage(copy.new_image);
      copy.image = result;
    }

    await upsertRestaurantProduct(restaurant, copy);

    webBack(goBack);
  };

  const onDelete = async () => {
    setSaving(true);

    onSubmit({ hidden: true });
  };

  useEffect(() => {
    setProduct(initProduct);
  }, [initProduct]);

  return (
    <>
      <Screen backgroundColor="white" style={tw`p-0`}>
        {isDesktop && <SimpleHeader style={{ marginTop: 25 }} />}
        <StatusBar barStyle={"light-content"} />
        <ImageBackground
          resizeMode="cover"
          source={product?.new_image || { uri: getFile(product.image) }}
          defaultSource={platform.appLogo}
          style={tw`w-full py-5 h-35`}
        >
          <ActionButton
            onPress={() => webBack(goBack)}
            style={tw`ml-5`}
            source="close"
          />
          <View style={tw`absolute bottom-5 right-5`}>
            <EditButton
              icon="images"
              label={t("admin.gallery")}
              e={() => {
                setImgLibrary(true);
              }}
              style={tw`mb-2`}
            />
            <EditButton
              icon="camera"
              e={async () => {
                const result = await pickImageAsync();
                if (result) {
                  setProduct({
                    ...product,
                    new_image: {
                      uri: result.uri,
                      base64: result.base64,
                    },
                  });
                }
              }}
            />
          </View>
        </ImageBackground>
        <View style={tw`bg-white py-5 px-4`}>
          <EditRow e={() => setProp({ field: "name", value: product.name })}>
            <Text style={tw`font-medium text-3xl`}>
              <TransProp v={product.name} />
            </Text>
          </EditRow>
          <EditRow
            icon="euro-sign"
            e={() =>
              setProp({ field: "price", value: product.price, type: "price" })
            }
          >
            <Text style={tw`font-medium flex-row items-center text-xl`}>
              {product.price.discounted > 0 && (
                <>
                  <Text style={{ ...tw`line-through`, ...styles.desc_xs }}>
                    {product.price.regular.toFixed(2)}€
                  </Text>
                  {"  "}
                </>
              )}
              {getProductPrice(product).toFixed(2)}€
            </Text>
          </EditRow>
          <EditRow
            e={() =>
              setProp({
                field: "description",
                value: product.description,
                multiline: true,
              })
            }
          >
            <Text style={styles.desc_s}>
              {product.description ? (
                <TransProp v={product.description} />
              ) : (
                t("field.description_desc")
              )}
            </Text>
          </EditRow>
          <YellowLine style={tw`w-full mt-4 mb-5`} />
          <TouchableOpacity
            style={tw`flex-row items-center`}
            onPress={(x) =>
              setProduct({ ...product, active: !product?.active })
            }
          >
            <Checkbox checked={product?.active} />
            <Text
              style={tw`flex-1 text-base ml-5 py-1.5 font-semibold text-black`}
            >
              {t("admin.show_product_menu")}
            </Text>
          </TouchableOpacity>
          {!product.active && (
            <BigButton
              style={tw`mt-5 py-2`}
              textStyle={{ color: platform.colors.red }}
              type="red-outline"
              disabled={saving}
              onPress={onDelete}
            >
              {t("word.delete_product")}
            </BigButton>
          )}
          <YellowLine style={tw`w-full mt-5 mb-5`} />
          <TouchableOpacity
            style={tw`flex-row items-center`}
            onPress={(x) =>
              setProduct({ ...product, allow_comment: !product?.allow_comment })
            }
          >
            <Checkbox checked={product?.allow_comment} />
            <Text
              style={tw`flex-1 text-base ml-5 py-1.5 font-semibold text-black`}
            >
              {t("admin.allow_comment")}
            </Text>
          </TouchableOpacity>
          <YellowLine style={tw`w-full mt-5 mb-5`} />
          <Text style={styles.header_2xl}>{t("word.category")}</Text>
          <View style={tw`flex-row items-center justify-between`}>
            <Text style={styles.header_light}>
              <TransProp v={product.category.name} />
            </Text>
            <EditButton
              label={t("word.switch")}
              icon="exchange-alt"
              e={() =>
                setProp({
                  field: "category",
                  value: product.category,
                  type: "category",
                })
              }
            />
          </View>
          <YellowLine style={tw`w-full mt-5 mb-5`} />
          <View style={tw`flex-row items-center justify-between mb-3`}>
            <Text style={styles.header_2xl}>{t("word.options")}</Text>
            <EditButton
              label={t("admin.add_option")}
              icon="plus"
              e={() =>
                setProp({ field: "options", value: null, type: "option" })
              }
            />
          </View>
          {product.options
            ?.filter((x) => !x._deleted)
            .map((o) => (
              <OptionCard key={`opt_${o.id}`} o={o}>
                <BigButton
                  type="red-outline"
                  style={tw`p-2 px-3`}
                  onPress={() => onOption(o, true)}
                  textStyle={tw`text-red-500`}
                >
                  X
                </BigButton>
              </OptionCard>
            ))}
        </View>
      </Screen>
      <View style={tw`bg-white border-gray-300 pb-5 pt-4 px-5 border-t`}>
        <BigButton disabled={saving} onPress={() => onSubmit()}>
          {t("word.save")}
        </BigButton>
      </View>
      <EditFieldModal
        field={prop?.field}
        saving={saving}
        value={prop?.value}
        type={prop?.type}
        multiline={prop?.multiline}
        onSubmit={onSaveProp}
        cancel={() => setProp(null)}
        categories={categories}
        options={options?.filter(
          (x) => !product.options?.find((y) => !y._deleted && y.id === x.id)
        )}
      />
      {imgLibrary && (
        <ImageLibrary
          onSubmit={(image) => [
            setProduct({
              ...product,
              image,
            }),
            setImgLibrary(null),
          ]}
          cancel={() => setImgLibrary(null)}
        />
      )}
    </>
  );
};

export default AdminProductEditModule;

export const PriceEditor = ({ data, onChange, style }) => {
  const platform = useContext(PlatformContext);
  const { t } = useTranslation();

  return (
    <View style={style}>
      {["regular", "discounted"].map((f) => (
        <View
          key={`price_${f}`}
          style={tw`flex-row items-center justify-between mb-3`}
        >
          <Text style={tw`text-lg font-semibold mr-3`}>{t(`price.${f}`)}</Text>
          <InputContainer style={tw`w-30 m-0`} key={`edit_price_${f}`}>
            <Text
              style={{
                ...tw`text-xl font-semibold mr-3`,
                color: platform.colors.yellow_main,
              }}
            >
              €
            </Text>
            <InputBase
              style={isIos ? tw`mb-2` : null}
              onChangeText={(t) =>
                onChange({ ...data, [f]: t?.toString()?.replace(",", ".") })
              }
              value={data?.[f]?.toString()}
              inputMode="decimal"
            />
          </InputContainer>
        </View>
      ))}
    </View>
  );
};

export const EditFieldModal = ({
  field,
  value,
  type = "text",
  multiline,
  categories,
  options,
  saving,
  onSubmit,
  cancel,
}) => {
  const { t } = useTranslation();
  const [form, setForm] = useState(value);

  useEffect(() => {
    setForm(value);
  }, [value]);

  if (!field) return null;

  const disabled =
    type === "text"
      ? saving
      : type === "price"
      ? saving || !form?.regular || form?.discounted >= form?.regular
      : type === "option"
      ? saving || !form?.id
      : saving;

  const toggleArray = (pId) => {
    const copy = [...(form || [])];
    const ind = copy.findIndex((x) => x === pId);
    if (ind === -1) copy.push(pId);
    else copy.splice(ind, 1);
    setForm(copy);
  };

  const inner =
    type === "text" ? (
      <LangField
        multiline={multiline}
        field={"value"}
        value={{ value: form }}
        onChange={({ value }) => setForm(value)}
      />
    ) : type === "category" ? (
      <ScrollView showsVerticalScrollIndicator={false}>
        {categories
          ?.filter((x) => x.active)
          .map((cat) => (
            <TouchableOpacity
              onPress={() => setForm(cat)}
              key={`cat_${cat.id}`}
              style={tw`flex-row justify-between items-center mb-5`}
            >
              <Text style={styles.header_lg}>
                <TransProp v={cat.name} />
              </Text>
              <RadioButton
                selected={form?.id === cat.id}
                onPress={() => setForm(cat)}
              />
            </TouchableOpacity>
          ))}
      </ScrollView>
    ) : type === "option" ? (
      <ScrollView showsVerticalScrollIndicator={false}>
        {options
          ?.filter((x) => x.active)
          .map((opt) => (
            <OptionCard
              key={`opt_${opt.id}`}
              o={opt}
              onPress={() => setForm(opt)}
            >
              <RadioButton
                selected={form?.id === opt.id}
                onPress={() => setForm(opt)}
              />
            </OptionCard>
          ))}
      </ScrollView>
    ) : type === "options" ? (
      <ScrollView showsVerticalScrollIndicator={false}>
        {options
          ?.filter((x) => x.active)
          .map((opt) => (
            <OptionCard
              key={`opt_${opt.id}`}
              o={opt}
              onPress={() => toggleArray(opt.id)}
            >
              <Checkbox checked={form?.includes(opt.id)} />
            </OptionCard>
          ))}
      </ScrollView>
    ) : type === "price" ? (
      <PriceEditor onChange={setForm} data={form} />
    ) : null;

  return (
    <HalfModal visible>
      <SimpleHeader
        hideLeft
        style={tw`mt-0 p-0 mb-5 h-auto`}
        title={
          type === "option"
            ? t("admin.add_option")
            : `${t("word.modify")} ${t(`field.${field}`)}`
        }
        right={{ onPress: cancel, source: "close" }}
      />
      {inner}
      <BigButton disabled={disabled} onPress={() => onSubmit(form)}>
        {t("word.save")}
      </BigButton>
    </HalfModal>
  );
};

export const ImageLibrary = ({ onSubmit, cancel }) => {
  const { t } = useTranslation();
  const { data, loading } = useApi(getTemplateImages);
  const [search, setSearch] = useState(null);

  const filterFunc = (x) =>
    !search ||
    search
      .trim()
      .split(" ")
      .map((keyword) =>
        JSON.stringify(x.tags).toLowerCase().includes(keyword.toLowerCase())
      )
      .filter((y) => y).length > 0;

  return (
    <HalfModal visible>
      <SimpleHeader
        hideLeft
        style={tw`mt-0 p-0 mb-5 h-auto`}
        title={t("admin.choose_picture")}
        right={{ onPress: cancel, source: "close" }}
      />
      <SimpleSearch
        style={{
          ...tw`my-3`,
        }}
        search={search}
        setSearch={setSearch}
      />
      <ScrollView
        contentContainerStyle={tw`flex-row items-center justify-center flex-wrap`}
      >
        {data?.filter(filterFunc).map((d, i) => (
          <TouchableOpacity
            key={`admin_edit_${i}`}
            onPress={() => onSubmit(d.image)}
            style={tw`w-1/2`}
          >
            <ImageBackground
              style={tw`h-45`}
              resizeMode="contain"
              source={{ uri: getFile(d.image, 250) }}
            />
          </TouchableOpacity>
        ))}
      </ScrollView>
    </HalfModal>
  );
};
