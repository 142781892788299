import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { UserContext } from "../contexts";

const useApi = (fetcher, params, reloader = [], defData = null) => {
  const { signOut } = useContext(UserContext);
  const { navigate } = useNavigation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(defData);
  const [error, setError] = useState(null);

  const load = useDebouncedCallback(async (silent = false) => {
    setLoading(!silent);

    const result = await fetcher(params);
    if (result.error) {
      if (result.error === "unauthorized") {
        signOut();
        navigate("quick-auth");
      }
      setError(result.error);
    } else {
      setData(result.data);
    }

    setLoading(false);

    return true;
  }, 50);

  useEffect(() => {
    load();
  }, reloader);

  return { loading, data, load, error, setData };
};

export default useApi;
