import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as Notifications from "expo-notifications";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import NavBar from "./components/NavBar";
import { PlatformContext, UserContext } from "./contexts";
import AccountModule from "./modules/AccountModule/AccountModule";
import AdminInvoicesModule from "./modules/Admin/AdminInvoicesModule/AdminInvoicesModule";
import AdminCategoriesModule from "./modules/Admin/CategoriesModule/AdminCategoriesModule";
import AdminChallenges from "./modules/Admin/Challenges/AdminChallenges";
import AdminCreateRestaurantModule from "./modules/Admin/CreateRestaurantModule/AdminCreateRestaurantModule";
import AdminHomeModule from "./modules/Admin/HomeModule/AdminHomeModule";
import AdminInfoModule from "./modules/Admin/InfoModule/AdminInfoModule";
import AdminInvoicingModule from "./modules/Admin/InvoicingModule/AdminInvoicingModule";
import AdminOpeningHoursModule from "./modules/Admin/OpeningHoursModule/AdminOpeningHoursModule";
import AdminOptionEditModule from "./modules/Admin/OptionEditModule/AdminOptionEditModule";
import AdminOptionsModule from "./modules/Admin/OptionsModule/AdminOptionsModule";
import AdminOrdersModule from "./modules/Admin/OrdersModule/AdminOrdersModule";
import AdminOverviewModule from "./modules/Admin/OverviewModule/AdminOverviewModule";
import AdminPrintTotalModule from "./modules/Admin/PrintTotalModule/AdminPrintTotalModule";
import AdminProductEditModule from "./modules/Admin/ProductEditModule/AdminProductEditModule";
import AdminProductsModule from "./modules/Admin/ProductsModule/AdminProductsModule";
import AdminStatusModule from "./modules/Admin/StatusModule/AdminStatusModule";
import AuthModule from "./modules/AuthModule/AuthModule";
import CheckoutModule from "./modules/CheckoutModule/CheckoutModule";
import FavRestaurantsModule from "./modules/FavRestaurantsModule/FavRestaurantsModule";
import HomeModule from "./modules/HomeModule/HomeModule";
import MapModule from "./modules/MapModule/MapModule";
import MenuModule from "./modules/MenuModule/MenuModule";
import OrderModule from "./modules/OrderModule/OrderModule";
import OrdersModule from "./modules/OrdersModule/OrdersModule";
import ProductModule from "./modules/ProductModule/ProductModule";
import QuickAuthModule from "./modules/QuickAuthModule/QuickAuthModule";
import { isDesktop, isWeb } from "./utils/platform";

const linking = {
	prefixes: ["fritzy://", "https://fritzy.be"],
	config: {},
};

const Stack = createNativeStackNavigator();
const MyRestaurant = createNativeStackNavigator();
const Tab = isDesktop ? createNativeStackNavigator() : createBottomTabNavigator();

const BottomTabs = () => {
	const platform = useContext(PlatformContext);
	const { user, restaurantView } = useContext(UserContext);
	const { t } = useTranslation();

	const screens_customer = [
		{
			name: "nearby",
			label: t(`route.nearby.${platform.vertical}`),
			module: HomeModule,
			icon: "location-arrow",
		},
		{ name: "orders", label: t("route.orders"), module: OrdersModule, icon: "utensils" },
		{
			name: "me",
			label: t(user?.id ? "route.account" : "checkout.sign_in"),
			module: AuthModule,
			icon: "user-alt",
		},
	];

	const screens_restaurant = [
		{ name: "orders", label: t("route.orders"), module: AdminOrdersModule, icon: "utensils" },
		{
			name: "restaurant-admin",
			label: t(`route.my_restaurants.${platform.vertical}`),
			module: AdminHomeModule,
			icon: "building",
		},
		{ name: "me", label: t("route.account"), module: AuthModule, icon: "user-alt" },
	];

	const screens = restaurantView ? screens_restaurant : screens_customer;

	return (
		<Tab.Navigator backBehavior="history" screenOptions={{ headerShown: false }}>
			{screens.map((s) => (
				<Tab.Screen
					key={s.name}
					name={s.name}
					component={s.module}
					options={{
						title: s.label,
						tabBarStyle: {
							backgroundColor: restaurantView ? platform.colors.yellow_main : "white",
						},
						tabBarAllowFontScaling: true,
						tabBarLabelStyle: { fontSize: 12, marginBottom: isWeb ? 5 : 0, fontWeight: "500" },
						tabBarActiveTintColor: restaurantView
							? platform.colors.background_dark
							: platform.colors.yellow_main,
						tabBarInactiveTintColor: restaurantView ? "white" : "gray",
						tabBarIcon: ({ focused }) => (
							<FontAwesome5
								name={s.icon}
								size={18}
								color={
									focused
										? restaurantView
											? platform.colors.background_dark
											: platform.colors.yellow_main
										: restaurantView
										? "white"
										: "gray"
								}
							/>
						),
					}}
				/>
			))}
		</Tab.Navigator>
	);
};

const NotificationHandler = () => {
	const { navigate } = useNavigation();
	const lastNotif = Notifications.useLastNotificationResponse();

	useEffect(() => {
		if (lastNotif) {
			const { data } = lastNotif.notification?.request?.content;
			if (data?.redirect_to) {
				navigate(data.redirect_to);
			}
		}
	}, [lastNotif]);

	return null;
};

const Router = () => {
	const platform = useContext(PlatformContext);
	const { t } = useTranslation();
	const { restaurantView } = useContext(UserContext);

	return (
		<NavigationContainer linking={linking} independent>
			<NotificationHandler />
			{isDesktop && <NavBar />}
			<Stack.Navigator
				screenOptions={{
					headerShown: false,
				}}
			>
				<Stack.Screen name="main" component={BottomTabs} />
				<Stack.Screen name="map" options={{ title: t("route.map") }} component={MapModule} />
				<Stack.Screen name="r" options={{ title: t("route.restaurant") }} component={MenuModule} />

				<Stack.Group screenOptions={{ presentation: "modal" }}>
					<Stack.Screen
						name="quick-auth"
						options={{ title: t("route.auth") }}
						component={QuickAuthModule}
					/>
					<Stack.Screen
						name="restaurant-product"
						options={{ title: t("route.product") }}
						component={ProductModule}
					/>
					{restaurantView && (
						<>
							<MyRestaurant.Screen name="admin-product-edit" component={AdminProductEditModule} />
							<MyRestaurant.Screen name="admin-option-edit" component={AdminOptionEditModule} />
						</>
					)}
				</Stack.Group>

				<Stack.Screen name="order" options={{ title: t("route.order") }} component={OrderModule} />
				<Stack.Screen
					name="favorite-restaurants"
					options={{ title: t(`account.favorite_restaurants.${platform.vertical}`) }}
					component={FavRestaurantsModule}
				/>
				<Stack.Screen
					name="account"
					options={{ title: t("route.account") }}
					component={AccountModule}
				/>
				<Stack.Screen
					name="restaurant-checkout"
					options={{ title: t("route.checkout") }}
					component={CheckoutModule}
				/>

				{restaurantView && (
					<>
						<MyRestaurant.Screen name="admin-home" component={AdminHomeModule} />
						<MyRestaurant.Screen name="admin-challenges" component={AdminChallenges} />
						<MyRestaurant.Screen name="admin-status" component={AdminStatusModule} />
						<MyRestaurant.Screen name="admin-overview" component={AdminOverviewModule} />
						<MyRestaurant.Screen name="admin-info" component={AdminInfoModule} />
						<MyRestaurant.Screen name="admin-invoicing" component={AdminInvoicingModule} />
						<MyRestaurant.Screen name="admin-opening-hours" component={AdminOpeningHoursModule} />
						<MyRestaurant.Screen name="admin-categories" component={AdminCategoriesModule} />
						<MyRestaurant.Screen name="admin-products" component={AdminProductsModule} />
						<MyRestaurant.Screen name="admin-print-total" component={AdminPrintTotalModule} />
						<MyRestaurant.Screen name="admin-invoices" component={AdminInvoicesModule} />
						<MyRestaurant.Screen name="admin-options" component={AdminOptionsModule} />
						<MyRestaurant.Screen
							name="admin-restaurant-create"
							component={AdminCreateRestaurantModule}
						/>
					</>
				)}
			</Stack.Navigator>
		</NavigationContainer>
	);
};

export default Router;
