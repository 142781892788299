import Icon from "@expo/vector-icons/Entypo";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ImageBackground,
  StatusBar,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import tw from "twrnc";
import ActionButton from "../../components/ActionButton";
import BigButton from "../../components/BigButton";
import Heading from "../../components/Heading";
import Options from "../../components/Options";
import Redirect from "../../components/Redirect";
import { TransProp } from "../../components/Trans";
import YellowLine from "../../components/YellowLine";
import { BagContext, PlatformContext } from "../../contexts";
import { track } from "../../utils/analytics";
import { getFile } from "../../utils/db";
import { hasTrans, webBack } from "../../utils/others";
import { isDesktop, isIos, isWeb } from "../../utils/platform";
import {
  getProductPrice,
  getProductPriceWithExtras,
} from "../../utils/products";
import { styles } from "../../utils/settings";
import Screen from "../Screen";

const ProductModule = ({
  onDone,
  restaurant: _restaurant,
  product: _product,
  selected: _selected,
}) => {
  const platform = useContext(PlatformContext);
  const { goBack } = useNavigation();
  const { t } = useTranslation();
  const {
    params: {
      restaurant: r_restaurant,
      product: r_product,
      selected: r_selected,
    },
  } = useRoute();
  const bags = useContext(BagContext);

  const restaurant = _restaurant || r_restaurant;
  const product = _product || r_product;
  const selected = _selected || r_selected;

  const [qty, setQty] = useState(selected?.qty || 1);
  const [extras, setExtras] = useState(selected?.extras || []);
  const [comment, setComment] = useState(selected?.comment || "");

  const hasAllRequired = () => {
    if (selected && qty === 0) return true;
    if (qty === 0) return false;

    const reqOptions = product?.options
      ?.filter((x) => x.required)
      .map((x) => x.id);

    let isOk = true;
    reqOptions.forEach((u) => {
      if (!isOk) return;

      isOk = extras?.findIndex((x) => x.option_id === u) > -1;
    });

    return isOk;
  };

  const onSubmit = () => {
    bags.upd(
      restaurant.id,
      product.id,
      "+",
      selected?.uuid,
      qty,
      extras,
      comment
    );
    if (onDone) onDone();
    else webBack(goBack);
  };

  useEffect(() => {
    if (restaurant?.id && product?.id)
      track("MENU_PRODUCT_VIEW", {
        restaurant: restaurant.id,
        product_id: product.id,
      });
  }, [restaurant, product]);

  if (!restaurant?.id || !product?.id) return <Redirect />;

  return (
    <>
      <Screen backgroundColor="white" style={tw`p-0`}>
        <StatusBar barStyle={"light-content"} />
        <ImageBackground
          resizeMode="cover"
          source={
            !product.image
              ? platform.appLogo
              : { uri: getFile(product.image, isDesktop ? 900 : 500, 100) }
          }
          defaultSource={platform.appLogo}
          style={tw`w-full py-5 ${isWeb ? "h-60" : "h-70"}`}
        >
          <ActionButton
            onPress={() => (onDone ? onDone() : webBack(goBack))}
            style={tw`ml-5`}
            source="close"
          />
        </ImageBackground>
        <View style={tw`bg-white py-5 px-4`}>
          <Text style={tw`font-medium text-3xl`}>
            <TransProp v={product.name} />
          </Text>

          <Text style={tw`font-medium text-xl`}>
            {product.price.discounted > 0 && (
              <>
                <Text style={{ ...tw`line-through`, ...styles.desc_xs }}>
                  {product.price.regular.toFixed(2)}€
                </Text>
                {"  "}
              </>
            )}
            {getProductPrice(product).toFixed(2)}€
          </Text>
          {hasTrans(product.description) && (
            <Text style={{ ...tw`mt-2`, ...styles.desc_s }}>
              <TransProp v={product.description} />
            </Text>
          )}
          {product.allow_comment || extras?.length > 0 ? (
            <YellowLine style={tw`my-7`} />
          ) : null}
          <Options extras={extras} setExtras={setExtras} product={product} />
          {product.allow_comment && (
            <View>
              <Heading style={tw`m-0 mb-0.5`}>{t("field.comment")}</Heading>
              <Text style={styles.desc_s}>{t("field.comment_desc")}</Text>
              <TextInput
                value={comment}
                onChangeText={(t) => setComment(t)}
                numberOfLines={5}
                multiline
                style={{
                  ...tw`w-full mt-5 p-3 rounded-xl h-30 bg-gray-100 border  border-gray-200`,
                  ...(isWeb && { outline: "none" }),
                }}
              />
            </View>
          )}
        </View>
      </Screen>
      <View
        style={tw`bg-white flex-row justify-between border-gray-300 ${
          isIos ? "pt-2 pb-4 px-5" : "py-2 px-2"
        } border-t`}
      >
        <View
          style={tw`flex-row justify-between items-center bg-gray-100 border border-gray-200 rounded-xl`}
        >
          <TouchableOpacity
            style={tw`p-3`}
            onPress={() => (qty > 0 ? setQty(qty - 1) : null)}
          >
            {selected && qty < 2 ? (
              <FontAwesome5 name="trash-alt" size={22} />
            ) : null}
            {!selected || qty >= 2 ? <Icon name="minus" size={20} /> : null}
          </TouchableOpacity>
          <Text style={tw`w-10 text-center`}>{qty}</Text>
          <TouchableOpacity style={tw`p-3`} onPress={() => setQty(qty + 1)}>
            <Icon name="plus" size={20} />
          </TouchableOpacity>
        </View>
        <BigButton
          style={tw`flex-1 ml-2`}
          disabled={!hasAllRequired()}
          onPress={onSubmit}
        >
          {selected && qty === 0 ? (
            t("word.remove")
          ) : (
            <>
              {selected?.uuid ? t("order.modify_bag") : t("order.add_to_bag")}:{" "}
              {getProductPriceWithExtras(product, qty, extras).toFixed(2)}€
            </>
          )}
        </BigButton>
      </View>
    </>
  );
};

export default ProductModule;
