import Icon from "@expo/vector-icons/Entypo";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../../components/BigButton";

import RNDateTimePicker from "@react-native-community/datetimepicker";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import Loading from "../../../components/Loading";
import SimpleHeader from "../../../components/SimpleHeader";
import { TransProp } from "../../../components/Trans";
import YellowLine from "../../../components/YellowLine";
import { PlatformContext } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import { getRestaurant, getRestaurantOrdersReport } from "../../../utils/db";
import { webBack } from "../../../utils/others";
import { isAndroid, isDesktop, isIos } from "../../../utils/platform";
import { printOrderReport } from "../../../utils/print";
import { styles } from "../../../utils/settings";

const AdminPrintTotalModule = () => {
  const platform = useContext(PlatformContext);
  const { goBack } = useNavigation();
  const [form, setForm] = useState({});
  const [saving, setSaving] = useState(false);
  const [step, setStep] = useState(0);
  const { t } = useTranslation();
  const {
    params: { restaurant },
  } = useRoute();
  const { data, loading } = useApi(getRestaurant, {
    id: restaurant,
    edit: true,
  });

  const [select, setSelect] = useState(null);
  const [from, setFrom] = useState(DateTime.now().toFormat("yyyy-MM-dd"));
  const [until, setUntil] = useState(DateTime.now().toFormat("yyyy-MM-dd"));

  useEffect(() => {
    setForm(data);
  }, [data]);

  const onSubmit = async () => {
    if (step === 0) {
      setSaving(true);

      const { data } = await getRestaurantOrdersReport({
        id: restaurant,
        from,
        until: DateTime.fromFormat(until, "yyyy-MM-dd")
          .plus({ days: 1 })
          .toFormat("yyyy-MM-dd"),
      });

      setForm((f) => ({ ...f, reporting: data }));

      setStep(1);

      setSaving(false);

      return;
    } else {
      if (form?.reporting?.days?.length > 0) {
        printOrderReport(form.reporting);
      }
      setStep(0);
    }
  };

  return (
    <View style={{ flex: 1, ...tw`bg-white` }}>
      <SimpleHeader
        left={{ onPress: () => (step === 1 ? setStep(0) : webBack(goBack)) }}
        title={TransProp({ v: form?.name })}
        style={isDesktop && { marginTop: 25 }}
      />

      {loading ? (
        <Loading />
      ) : step === 0 ? (
        <ScrollView
          style={tw`flex-1 p-3`}
          contentContainerStyle={{ paddingBottom: 20 }}
        >
          <View style={tw`flex-row items-center justify-between`}>
            <Text style={{ ...styles.header_lg, ...tw`` }}>
              {t("admin.print_from")}:
            </Text>
            <TouchableOpacity onPress={() => setSelect("from")}>
              <Text style={{ ...styles.header_lg, ...tw`` }}>
                {DateTime.fromFormat(from, "yyyy-MM-dd").toFormat("dd-MM-yyyy")}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={tw`flex-row items-center justify-between mt-3`}>
            <Text style={{ ...styles.header_lg, ...tw`` }}>
              {t("admin.print_until")}:
            </Text>
            <TouchableOpacity onPress={() => setSelect("until")}>
              <Text style={{ ...styles.header_lg, ...tw`` }}>
                {DateTime.fromFormat(until, "yyyy-MM-dd").toFormat(
                  "dd-MM-yyyy"
                )}
              </Text>
            </TouchableOpacity>
          </View>
          {select && (
            <RNDateTimePicker
              value={DateTime.fromFormat(
                select === "from" ? from : until,
                "yyyy-MM-dd"
              ).toJSDate()}
              onChange={(a, b) => {
                if (select === "from")
                  setFrom(DateTime.fromJSDate(b).toFormat("yyyy-MM-dd"));
                else setUntil(DateTime.fromJSDate(b).toFormat("yyyy-MM-dd"));

                setSelect(null);
              }}
              mode="date"
              display={isIos ? "inline" : "calendar"}
            />
          )}
        </ScrollView>
      ) : step === 1 ? (
        <ScrollView
          style={tw`flex-1 p-3`}
          contentContainerStyle={{ paddingBottom: 40 }}
        >
          <Text style={{ ...styles.header_xl, ...tw`mb-3` }}>
            {t("admin.print_total_ticket")}
          </Text>
          <YellowLine style={tw`mb-3`} />
          <View
            key={`day_title`}
            style={tw`flex-row justify-end items-center mb-3`}
          >
            <Text style={{ ...styles.header_lg, ...tw`w-25 text-right mr-3` }}>
              {t("word.total_gross")}
            </Text>
            <Text style={{ ...styles.header_lg, ...tw`w-25 text-right` }}>
              {t("word.total_net")}
            </Text>
          </View>
          {form?.reporting?.days?.map((d, i) => (
            <View
              key={`day_${i}`}
              style={tw`flex-row justify-between items-center mb-3`}
            >
              <Text style={{ ...styles.header_light, ...tw`` }}>
                {DateTime.fromISO(d.date).toFormat("dd/MM/yyyy")}
              </Text>
              <View style={tw`flex-row items-center justify-start w-10`}>
                <Icon
                  color={platform.colors.yellow_main}
                  style={tw`mr-2`}
                  name="shopping-cart"
                  size={20}
                />
                <Text style={{ ...styles.header_light, ...tw`` }}>
                  {d.nr_orders}
                </Text>
              </View>
              <Text style={{ ...styles.header_light, ...tw`w-25 text-right` }}>
                {d.total_orders.toFixed(2)} €
              </Text>
              <Text style={{ ...styles.header_light, ...tw`w-25 text-right` }}>
                {d.total_restaurant.toFixed(2)} €
              </Text>
            </View>
          ))}
          <YellowLine style={tw`my-3`} />
          <View style={tw`flex-row justify-between items-center mt-2`}>
            <Text style={{ ...styles.header_lg, ...tw`` }}>
              {t("word.total_orders")}
            </Text>
            <View style={tw`flex-row items-center justify-start w-15`}>
              <Icon
                color={platform.colors.yellow_main}
                style={tw`mr-2`}
                name="shopping-cart"
                size={20}
              />
              <Text style={{ ...styles.header_lg, ...tw`` }}>
                {form?.reporting?.total_restaurant_orders}
              </Text>
            </View>
          </View>
          <View style={tw`flex-row justify-between items-center mt-2`}>
            <Text style={{ ...styles.header_lg, ...tw`` }}>
              {t("word.total_gross")}
            </Text>
            <Text style={{ ...styles.header_lg, ...tw`` }}>
              {form?.reporting?.total_orders_sum.toFixed(2)} €
            </Text>
          </View>
          <Text style={{ ...styles.desc_xs, ...tw`mb-2` }}>
            {t("word.total_gross_desc")}
          </Text>
          <View style={tw`flex-row justify-between items-center mt-2`}>
            <Text style={{ ...styles.header_lg, ...tw`` }}>
              {t("word.total_net")}
            </Text>
            <Text style={{ ...styles.header_lg, ...tw`` }}>
              {form?.reporting?.total_restaurant_sum.toFixed(2)} €
            </Text>
          </View>
          <Text style={{ ...styles.desc_xs, ...tw`mb-2` }}>
            {t("word.total_net_desc")}
          </Text>
          <YellowLine style={tw`my-3`} />
          <Text style={{ ...styles.header_xl, ...tw`mb-3` }}>
            {t("admin.print_total_products")}
          </Text>
          <YellowLine style={tw`mb-3`} />
          <View
            key={`product_title`}
            style={tw`flex-row justify-between items-center mb-3`}
          >
            <Text style={{ ...styles.header_lg, ...tw`w-10 text-right` }}>
              {" "}
            </Text>
            <Text style={{ ...styles.header_lg, ...tw`w-25 text-right` }}>
              {t("field.price")}
            </Text>
            <Text style={{ ...styles.header_lg, ...tw`w-25 text-right` }}>
              {t("word.total")}
            </Text>
          </View>
          {form?.reporting?.products_sold?.map((d, i) => (
            <View>
              <Text style={{ ...styles.header_light, ...tw`` }}>
                {d.category} - {d.name}
              </Text>
              <View
                key={`product_${i}`}
                style={tw`flex-row justify-between items-center mb-3`}
              >
                <View style={tw`flex-row items-center justify-start w-10`}>
                  <Icon
                    color={platform.colors.yellow_main}
                    style={tw`mr-2`}
                    name="shopping-cart"
                    size={20}
                  />
                  <Text style={{ ...styles.header_light, ...tw`` }}>
                    {d.quantity}
                  </Text>
                </View>
                <Text
                  style={{ ...styles.header_light, ...tw`w-25 text-right` }}
                >
                  {d.price.toFixed(2)} €
                </Text>
                <Text
                  style={{ ...styles.header_light, ...tw`w-25 text-right` }}
                >
                  {d.total.toFixed(2)} €
                </Text>
              </View>
            </View>
          ))}
          <YellowLine style={tw`my-3`} />
          <Text style={{ ...styles.header_xl, ...tw`mb-3` }}>
            {t("admin.print_total_options")}
          </Text>
          <YellowLine style={tw`mb-3`} />
          <View
            key={`product_title`}
            style={tw`flex-row justify-between items-center mb-3`}
          >
            <Text style={{ ...styles.header_lg, ...tw`w-10 text-right` }}>
              {" "}
            </Text>
            <Text style={{ ...styles.header_lg, ...tw`w-25 text-right` }}>
              {t("field.price")}
            </Text>
            <Text style={{ ...styles.header_lg, ...tw`w-25 text-right` }}>
              {t("word.total")}
            </Text>
          </View>
          {form?.reporting?.options_sold?.map((d, i) => (
            <View>
              <Text style={{ ...styles.header_light, ...tw`` }}>
                {d.category} - {d.name}
              </Text>
              <View
                key={`product_${i}`}
                style={tw`flex-row justify-between items-center mb-3`}
              >
                <View style={tw`flex-row items-center justify-start w-10`}>
                  <Icon
                    color={platform.colors.yellow_main}
                    style={tw`mr-2`}
                    name="shopping-cart"
                    size={20}
                  />
                  <Text style={{ ...styles.header_light, ...tw`` }}>
                    {d.quantity}
                  </Text>
                </View>
                <Text
                  style={{ ...styles.header_light, ...tw`w-25 text-right` }}
                >
                  {d.price.toFixed(2)} €
                </Text>
                <Text
                  style={{ ...styles.header_light, ...tw`w-25 text-right` }}
                >
                  {d.total.toFixed(2)} €
                </Text>
              </View>
            </View>
          ))}
        </ScrollView>
      ) : null}
      {(isAndroid || step === 0) && (
        <View
          style={tw`bg-white border-gray-300 ${
            isIos ? "pb-5 pt-4 px-5" : "py-3 px-3"
          } border-t`}
        >
          <BigButton onPress={onSubmit} disabled={saving}>
            {step === 0 ? t("word.load") : t("word.print")}
          </BigButton>
        </View>
      )}
    </View>
  );
};

export default AdminPrintTotalModule;
