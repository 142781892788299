import Icon from "@expo/vector-icons/Entypo";
import { useNavigation, useRoute } from "@react-navigation/native";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import tw from "twrnc";
import BigButton from "../../components/BigButton";
import CheckoutCard from "../../components/CheckoutCard";
import CheckoutProductCard from "../../components/CheckoutProductCard";
import Footer from "../../components/Footer";
import HalfModal from "../../components/HalfModal";
import Loading from "../../components/Loading";
import Mapper from "../../components/Mapper";
import PaymentMethod from "../../components/PaymentMethod";
import SimpleHeader from "../../components/SimpleHeader";
import Socketor from "../../components/Socketor";
import { TransProp } from "../../components/Trans";
import YellowLine from "../../components/YellowLine";
import { BagContext, PlatformContext, UserContext } from "../../contexts";
import useApi from "../../hooks/useApi";
import { track } from "../../utils/analytics";
import { getUserOrder, updateOrder } from "../../utils/db";
import lists from "../../utils/lists";
import { openMap, parseFee, parseRouteJson, upF } from "../../utils/others";
import { isDesktop } from "../../utils/platform";
import { styles } from "../../utils/settings";
import Screen from "../Screen";

const horizontal = isDesktop && window.innerWidth > 1400;

const StatusCard = ({
  title,
  subtitle,
  enabled,
  children,
  backgroundColor = "white",
  actions,
}) => (
  <View
    style={{
      ...tw`flex flex-row ${
        isDesktop ? "justify-center" : "justify-between"
      } items-center mb-5 ${
        horizontal ? "" : `w-full pl-3.5 ${isDesktop ? "" : "pr-35"}`
      } ${enabled ? "opacity-100" : "opacity-50"}`,
      width: horizontal ? "30%" : isDesktop ? "60%" : "100%",
    }}
  >
    <View
      style={{
        ...tw`flex-col items-center justify-center w-25 h-25 mr-5 rounded-full`,
        marginLeft: isDesktop ? "15%" : null,
        backgroundColor,
      }}
    >
      {children}
    </View>
    <View style={{ width: isDesktop ? "50%" : "100%" }}>
      <Text style={styles.header_xl}>{title}</Text>
      <Text style={styles.header_extralight}>{subtitle}</Text>
      {actions}
    </View>
  </View>
);

const OrderModule = () => {
  const platform = useContext(PlatformContext);
  const { navigate } = useNavigation();
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const {
    params: { order_id, restaurant_id, redirect_status, order: init_order },
  } = useRoute();
  const { lang, user } = useContext(UserContext);
  const bags = useContext(BagContext);
  const {
    data: order,
    loading,
    load,
  } = useApi(getUserOrder, order_id, [order_id], parseRouteJson(init_order));

  // clear bag after order
  useEffect(() => {
    if (restaurant_id) bags.clearBag(restaurant_id);
  }, [restaurant_id]);

  useEffect(() => {
    if (redirect_status && redirect_status === "succeeded")
      track("CART_CHECKOUT_SUCCESS", { restaurant_id, order_id });
  }, [redirect_status]);

  if (loading || !order || !order?.id || !user?.id) return <Loading />;

  const isFailed = redirect_status === "failed";
  const isRunning =
    [1, 2, 5].includes(order.status) ||
    (order.status === 6 &&
      DateTime.fromISO(order.pickup_at).plus({ minutes: 60 }) > DateTime.now());

  const onCancel = async () => {
    if (cancelling) return;

    setCancelling(true);

    await updateOrder(order.id, {
      action: "cancel_by_customer",
    });

    setCancelling(false);

    load();
  };

  const isReady =
    (order.pickup_at &&
      DateTime.fromISO(order.pickup_at).diffNow() <= 0 &&
      order.status > 2) ||
    order.status > 5;

  return (
    <>
      <Screen
        backgroundColor={isRunning ? platform.colors.yellow_main : "white"}
        style={tw`p-0`}
      >
        <Socketor
          room={`customer/${user.id}/orders`}
          messages={["order_change"]}
          onMessage={(p) => load()}
        />
        <View
          style={{
            ...tw`flex flex-col items-center justify-center px-3`,
            ...(isDesktop && { marginTop: 100 }),
          }}
        >
          <SimpleHeader
            style={tw`w-full`}
            left={
              !isDesktop
                ? { onPress: () => navigate("main", { screen: "orders" }) }
                : null
            }
            title={t("orders.order_nr", order)}
          />
          {!isRunning && (
            <View
              style={{
                ...tw`w-full`,
                ...(isDesktop && { paddingLeft: "25%", paddingRight: "25%" }),
              }}
            >
              <CheckoutCard
                meta={order}
                style={isDesktop ? tw`m-0` : null}
                restaurant={order.restaurant}
                simple
                isSuccess={[5, 6].includes(order.status)}
              >
                {order.eat_in && (
                  <Text style={styles.neutral_me}>
                    {t("word.eat_in_places")}
                    {": "}
                    <TransProp v={order.eat_in?.name} />
                  </Text>
                )}
                <Text
                  style={
                    [5, 6].includes(order.status)
                      ? styles.success_me
                      : styles.error_me
                  }
                >
                  {t(`order.status.${lists.order_status[order.status]}`)}
                </Text>
              </CheckoutCard>
            </View>
          )}
          {isRunning && (
            <>
              <Text
                style={{
                  ...styles.header_3xl,
                  ...tw`mt-5`,
                  textAlign: "center",
                }}
              >
                <TransProp v={order.restaurant.name} />
              </Text>
              <Text style={{ ...styles.header_light, textAlign: "center" }}>
                {t(`order.status.${lists.order_status[order.status]}`)}
              </Text>
              <View
                style={tw`flex ${
                  horizontal ? "flex-row" : "flex-col"
                } items-center justify-center mt-10 mb-5 w-full`}
              >
                {!horizontal && !isDesktop ? (
                  <View
                    style={{
                      width: 1,
                      backgroundColor: "white",
                      height: isReady ? "70%" : order.status > 3 ? "60%" : "0%",
                      position: "absolute",
                      top: 0,
                      left: 65,
                    }}
                  />
                ) : null}
                {isFailed ? (
                  <StatusCard
                    title={t("order.payment_failed")}
                    subtitle={t("order.payment_failed_desc")}
                    backgroundColor="black"
                    enabled
                    actions={
                      <BigButton
                        type="dark-outline"
                        style={tw`py-2 mt-2`}
                        onPress={() =>
                          navigate("r", { id: order.restaurant.id })
                        }
                      >
                        {t("word.try_again")}
                      </BigButton>
                    }
                  >
                    <Image
                      source={require("../../../assets/step-1.png")}
                      style={{
                        width: 40,
                        height: 40,
                        tintColor: platform.colors.yellow_main,
                      }}
                    />
                  </StatusCard>
                ) : (
                  <StatusCard
                    title={t("order.order_sent")}
                    subtitle={upF(
                      `${DateTime.fromISO(order.created_at).toRelativeCalendar({
                        locale: lang,
                      })} ${t("time.at")} ${DateTime.fromISO(
                        order.created_at
                      ).toLocaleString(DateTime.TIME_24_SIMPLE, {
                        locale: lang,
                      })}`
                    )}
                    enabled
                  >
                    <Image
                      source={require("../../../assets/step-1.png")}
                      style={{
                        width: 40,
                        height: 40,
                        tintColor: platform.colors.yellow_main,
                      }}
                    />
                  </StatusCard>
                )}
                <StatusCard
                  title={t("order.running")}
                  subtitle={
                    order.status > 2 && order.pickup_at
                      ? `${t("order.ready_at")} ${DateTime.fromISO(
                          order.pickup_at
                        ).toLocaleString(DateTime.TIME_24_SIMPLE)}`
                      : t("order.status.awaiting_confirmation_restaurant")
                  }
                  enabled={order.status > 2}
                >
                  <Image
                    source={require("../../../assets/step-2.png")}
                    style={{
                      width: 40,
                      height: 40,
                      tintColor: platform.colors.yellow_main,
                    }}
                  />
                </StatusCard>
                <StatusCard
                  title={t("order.enjoy")}
                  subtitle={
                    order?.eat_in ? (
                      <>
                        {t("word.eat_in_places")}
                        {": "}
                        <TransProp v={order.eat_in?.name} />
                      </>
                    ) : (
                      t(
                        order.delivery
                          ? "order.will_be_delivered"
                          : "order.can_go"
                      )
                    )
                  }
                  enabled={isReady}
                >
                  <Image
                    source={require("../../../assets/step-3.png")}
                    style={{
                      width: 40,
                      height: 40,
                      tintColor: platform.colors.yellow_main,
                    }}
                  />
                </StatusCard>
              </View>
              <View style={tw`bg-white p-5 rounded-2xl w-90 mb-10`}>
                <View style={tw`flex-row`}>
                  <Icon
                    style={tw`mt-1`}
                    name="location-pin"
                    color={platform.colors.red}
                    size={24}
                  />
                  <ImageBackground
                    source={require("../../../assets/toe.png")}
                    resizeMode="contain"
                    style={{
                      height: 121,
                      width: 135,
                      position: "absolute",
                      right: -70,
                      bottom: -45,
                    }}
                  />
                  <View
                    style={{
                      backgroundColor: "white",
                      transform: [{ rotate: "45deg" }],
                      height: 40,
                      width: 40,
                      position: "absolute",
                      left: 12.5,
                      top: -30,
                    }}
                  ></View>
                  {order.delivery ? (
                    <View style={tw`ml-1`}>
                      <Text style={styles.header_me}>
                        {t("delivery.deliver_to")}:
                      </Text>
                      <Text style={styles.header_light}>
                        {order.delivery.street} {order.delivery.street_number}
                        {order.delivery.addition || ""}
                      </Text>
                      <Text style={styles.header_light}>
                        {order.delivery.postcode} {order.delivery.city} (
                        {order.delivery.country})
                      </Text>
                      {order.delivery.comment && (
                        <Text style={{ ...styles.desc_s, ...tw`text-black` }}>
                          {order.delivery.comment}
                        </Text>
                      )}
                    </View>
                  ) : (
                    <View style={tw`ml-1`}>
                      <Text style={styles.header_light}>
                        {order.restaurant.address.street}{" "}
                        {order.restaurant.address.street_number}
                      </Text>
                      <Text style={styles.header_light}>
                        {order.restaurant.address.city}{" "}
                        {order.restaurant.address.postcode}
                      </Text>
                      <TouchableOpacity
                        onPress={() =>
                          openMap(
                            order.restaurant.name.en,
                            order.restaurant.address
                          )
                        }
                      >
                        <Text style={{ ...styles.link, ...tw`mt-2` }}>
                          {t("order.directions_restaurant")}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              </View>
            </>
          )}
        </View>
        <View
          style={{
            ...tw`bg-white px-4 w-full ${
              isDesktop ? "pt-15" : "pt-5"
            } pb-15 flex-col`,
            ...(isDesktop && { paddingLeft: "25%", paddingRight: "25%" }),
          }}
        >
          <Text style={styles.header_2xl}>{t("order.details")}</Text>
          <YellowLine style={tw`my-5`} />
          <View style={tw`bg-white w-full`}>
            <Mapper
              loading={loading}
              data={order.items}
              render={(item, i) => (
                <CheckoutProductCard
                  key={`item_${i}`}
                  item={{
                    ...item,
                    qty: item.quantity,
                  }}
                  disabled
                  isLast={order.items.length === i + 1}
                />
              )}
            />
          </View>
          <View style={tw`mt-10 border-t border-gray-300 py-5`}>
            {order.delivery && (
              <View style={tw`flex-row justify-between`}>
                <View>
                  <Text style={styles.header_lg}>{t("delivery.fee")}</Text>
                </View>
                <Text style={styles.header_lg}>
                  {order.delivery_fee
                    ? `${order.delivery_fee.toFixed(2)}€`
                    : t("word.free")}
                </Text>
              </View>
            )}
            <View style={tw`flex-row justify-between`}>
              <View>
                <Text style={styles.header_2xl}>{t("order.total_amount")}</Text>
              </View>
              <Text style={styles.header_2xl}>
                {order.amount_total.toFixed(2)}€
              </Text>
            </View>
            <TouchableOpacity
              onPress={() => setModal(true)}
              style={tw`flex-row justify-between`}
            >
              <View>
                <Text style={{ ...styles.desc_s, ...tw`text-gray-600` }}>
                  {t(
                    !order.restaurant.customer_fee
                      ? "orders.fee_for_restaurant"
                      : "orders.fee_included",
                    {
                      name: order.restaurant?.name?.[lang],
                      amount: parseFee(order.restaurant?.customer_fee).toFixed(
                        2
                      ),
                    }
                  )}
                </Text>
              </View>
            </TouchableOpacity>
            {order.refunded && (
              <TouchableOpacity onPress={() => setModal(true)} style={tw`mt-2`}>
                <Text style={styles.error_me}>
                  {t("order.total_amount_refunded")}
                </Text>
              </TouchableOpacity>
            )}
          </View>
          {order.paid_at && (
            <View style={tw`border-t border-gray-300 py-5`}>
              <View style={tw`flex-row justify-between`}>
                <View>
                  <Text style={styles.header_lg}>
                    {t("orders.payment_method_chosen")}
                  </Text>
                  <Text style={{ ...styles.desc_s, ...tw`text-gray-600` }}>
                    {t("orders.paid_with", {
                      label: `${order.payment_method?.toUpperCase()} ${
                        order.payment_card || ""
                      }`,
                    })}
                  </Text>
                </View>
                <PaymentMethod method={order.payment_method} />
              </View>
            </View>
          )}
          {order.payment_type === 1 && (
            <View style={tw`border-t border-gray-300 py-5`}>
              <View style={tw`flex-row justify-between`}>
                <View>
                  <Text style={styles.header_lg}>
                    {t("checkout.title")}: {t("checkout.payment_type_1")}
                  </Text>
                </View>
                <PaymentMethod method="cash" />
              </View>
            </View>
          )}
          {order.status === 2 && (
            <TouchableOpacity
              disabled={cancelling}
              onPress={onCancel}
              style={tw`mt-5 flex-col items-center`}
            >
              <Text style={styles.link}>{t("order.cancel")}</Text>
            </TouchableOpacity>
          )}
        </View>
        {isDesktop && <Footer style={tw`bg-white`} />}
      </Screen>

      <HalfModal visible={modal}>
        <SimpleHeader
          hideLeft
          style={tw`mt-0 p-0 mb-5 h-auto`}
          title={t("fees.title")}
        />
        <Text>{t("fees.desc")}</Text>
        <View style={tw`w-full mt-5`}>
          <BigButton onPress={() => setModal(false)}>
            {t("word.okay")}
          </BigButton>
        </View>
      </HalfModal>
    </>
  );
};

export default OrderModule;
