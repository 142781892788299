import "react-native-get-random-values";

import { useNetInfo } from "@react-native-community/netinfo";
import { StripeProvider } from "@stripe/stripe-react-native";
import Constants from "expo-constants";
import * as Device from "expo-device";
import { useFonts } from "expo-font";
import * as Notifications from "expo-notifications";
import * as SplashScreen from "expo-splash-screen";
import { useEffect, useState } from "react";
import { Platform, View } from "react-native";
import "react-native-gesture-handler";
import Loading from "./components/Loading";
import NoInternet from "./components/NoInternet";
import { BagContext, PlatformContext, UserContext } from "./contexts";
import useAuth from "./hooks/useAuth";
import useBag from "./hooks/useBag";
import usePlatform from "./hooks/usePlatform";
import Router from "./router";
import { updateUserNotificationToken } from "./utils/db";
import { isWeb } from "./utils/platform";
import settings from "./utils/settings";

SplashScreen.preventAutoHideAsync();

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export async function registerForPushNotificationsAsync() {
  let token;

  if (Platform.OS === "android") {
    await Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      // alert("Failed to get push token for push notification!");
      return;
    }
    token = (
      await Notifications.getExpoPushTokenAsync({
        projectId: Constants.expoConfig.extra.eas.projectId,
      })
    ).data;

    if (token) updateUserNotificationToken(token);
  }

  return token;
}

const Container = ({ children }) => {
  if (Platform.OS === "web") return <View style={{ flex: 1 }}>{children}</View>;

  return (
    <StripeProvider
      publishableKey={settings.stripe.pk}
      urlScheme="com.fritzybe.app"
      merchantIdentifier="merchant.com.fritzybe.app"
      setReturnUrlSchemeOnAndroid={true}
    >
      {children}
    </StripeProvider>
  );
};

export default function App() {
  const netInfo = useNetInfo();
  const [disconnected, setDisconnected] = useState(false);
  const [fontsLoaded] = useFonts({
    Autoguard: require("../assets/fonts/Autoguard.otf"),
    FarsonSolid: require("../assets/fonts/FarsonSolid.otf"),
  });
  const platform = usePlatform();
  const auth = useAuth();
  const bag = useBag();

  useEffect(() => {
    if (auth.user && !isWeb) registerForPushNotificationsAsync();
  }, [auth.user]);

  useEffect(() => {
    if (fontsLoaded) SplashScreen.hideAsync();
  }, [fontsLoaded]);

  useEffect(() => {
    if (isWeb && platform.isPizzy) {
      var links = document.querySelectorAll("link[rel~='icon']");
      links?.forEach((l) => {
        l.href = "https://fritzy.be/favicon-pizzy.ico";
      });
    }
  }, []);

  useEffect(() => {
    if (isWeb || netInfo?.isConnected === null) return;

    setTimeout(() => {
      setDisconnected(!netInfo?.isConnected);
    }, 1000);
  }, [netInfo?.isConnected]);

  if (!fontsLoaded) return <Loading platform={platform} />;
  if (disconnected) return <NoInternet />;

  return (
    <Container>
      <PlatformContext.Provider value={platform}>
        <UserContext.Provider value={auth}>
          <BagContext.Provider value={bag}>
            <Router />
          </BagContext.Provider>
        </UserContext.Provider>
      </PlatformContext.Provider>
    </Container>
  );
}
