import { sendAnalytics } from "./db";

const events = [];

export const track = async (event, data) => {
  events.push({ event, data: data || {} });

  if (events.length >= 5) {
    sendAnalytics({ events: [...events] });
    events.length = 0;
  }
};
