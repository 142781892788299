import { useNavigation } from "@react-navigation/native";
import Constants from "expo-constants";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../../components/BigButton";
import HalfModal from "../../../components/HalfModal";
import Mapper from "../../../components/Mapper";
import RestaurantOrderCard from "../../../components/RestaurantOrderCard";
import SimpleHeader from "../../../components/SimpleHeader";
import Socketor from "../../../components/Socketor";
import SoundPlayer from "../../../components/SoundPlayer";
import Tabs from "../../../components/Tabs";
import { TransProp } from "../../../components/Trans";
import { PlatformContext, UserContext } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import useFocusLoad from "../../../hooks/useFocusLoad";
import { getRestaurantOrders } from "../../../utils/db";
import { isAndroid, isDesktop } from "../../../utils/platform";
import { printOrder } from "../../../utils/print";

const OrdersListContainer = ({ children }) => {
  if (isDesktop)
    return (
      <ScrollView
        style={tw`flex-1`}
        contentContainerStyle={tw`flex-row p-3 flex flex-wrap gap-3 justify-between`}
      >
        {children}
      </ScrollView>
    );

  return (
    <ScrollView style={tw`flex-1 p-3`} contentContainerStyle={tw`pb-10`}>
      {children}
    </ScrollView>
  );
};

const OrdersList = ({
  selectedRestaurant,
  reload,
  filter,
  focus,
  limit = 20,
  showMore,
  track,
}) => {
  const platform = useContext(PlatformContext);
  const { lang: userLang } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const { data, loading, load } = useApi(
    getRestaurantOrders,
    {
      filter,
      restaurant: selectedRestaurant?.id,
      limit: limit * page,
      skip: 0,
    },
    [focus, page]
  );

  useEffect(() => {
    if (reload) load(true);
  }, [reload]);

  return (
    <OrdersListContainer>
      {track && <SoundPlayer play={data?.length > 0} track={track} />}

      <Mapper
        loading={!data && loading}
        data={data}
        render={(order, i) => (
          <RestaurantOrderCard
            key={`order_${order.id}`}
            onLongPress={() => printOrder(order, 0, platform, userLang)}
            order={order}
            onReload={() => load(true)}
          />
        )}
      />
      {showMore && data && !loading ? (
        <BigButton type="yellow-outline" onPress={() => setPage(page + 1)}>
          {t("word.show_all")}
        </BigButton>
      ) : null}
    </OrdersListContainer>
  );
};

const AdminOrdersModule = () => {
  const [modal, setModal] = useState(false);
  const platform = useContext(PlatformContext);
  const { t } = useTranslation();
  const { myRestaurants } = useContext(UserContext);
  const { navigate } = useNavigation();
  const focus = useFocusLoad();
  const [reload, setReload] = useState(0);
  const filteredData = myRestaurants
    ?.filter((x) => !x.is_test && x.validated)
    ?.reverse();
  const [selectedRestaurant, setSelectedRestaurant] = useState(
    filteredData?.[0]
  );

  useEffect(() => {
    if (!selectedRestaurant && filteredData?.length > 0)
      setSelectedRestaurant(filteredData[0]);
  }, [filteredData]);

  return (
    <View
      style={{
        flex: 1,
        ...tw`bg-white`,
        paddingTop: isAndroid ? 0 : Constants.statusBarHeight,
      }}
    >
      {isDesktop && <SimpleHeader style={{ marginTop: 25 }} />}
      {selectedRestaurant?.id && (
        <Socketor
          key={`socketor_${selectedRestaurant.id}`}
          room={`restaurant/${selectedRestaurant.id}/orders`}
          messages={["new_order", "active_order", "past_order"]}
          onMessage={(p) => setReload(new Date().getTime())}
          poll={45000}
        />
      )}
      {filteredData?.length > 1 && selectedRestaurant ? (
        <BigButton
          textStyle={{ color: "black" }}
          onPress={() => setModal(true)}
          type="yellow-outline"
          style={tw`m-1 py-1`}
        >
          <TransProp v={selectedRestaurant?.name} />
        </BigButton>
      ) : null}
      {selectedRestaurant && (
        <Tabs
          defaultTab={1}
          tabs={[
            {
              key: "new",
              title: t("orders.new"),
              component: () => (
                <OrdersList
                  selectedRestaurant={selectedRestaurant}
                  reload={reload}
                  focus={focus}
                  filter="restaurant-new"
                  sockets={["new_order"]}
                  track={selectedRestaurant.sound}
                />
              ),
            },
            {
              key: "active",
              title: t("orders.pending"),
              component: () => (
                <OrdersList
                  selectedRestaurant={selectedRestaurant}
                  reload={reload}
                  focus={focus}
                  filter="restaurant-active"
                  sockets={["new_order", "active_order"]}
                />
              ),
            },
            {
              key: "past",
              title: t("orders.past"),
              component: () => (
                <OrdersList
                  selectedRestaurant={selectedRestaurant}
                  focus={focus}
                  showMore
                  filter="restaurant-past"
                  limit={3}
                  sockets={["past_order"]}
                />
              ),
            },
          ]}
        />
      )}
      <View style={tw`absolute bottom-1 right-1`}>
        <BigButton
          type={platform.isPizzy ? "yellow" : "dark"}
          onPress={() =>
            navigate("admin-status", { restaurant: selectedRestaurant?.id })
          }
          icon="cog"
          iconColor={"white"}
        />
      </View>
      <View style={tw`absolute bottom-1 left-1`}>
        <BigButton
          type={platform.isPizzy ? "yellow" : "dark"}
          onPress={() =>
            navigate("admin-products", { restaurant: selectedRestaurant?.id })
          }
          icon="shopping-cart"
          iconColor={"white"}
        />
      </View>
      {modal && (
        <HalfModal visible>
          <SimpleHeader
            hideLeft
            style={tw`mt-0 p-0 mb-2 h-auto`}
            title={t(`admin.my_restaurants.${platform.vertical}`)}
            right={{ onPress: () => setModal(false), source: "close" }}
          />
          {filteredData?.map((r) => (
            <BigButton
              onPress={() => [setSelectedRestaurant(r), setModal(false)]}
              key={`restaurant_${r.id}`}
              type="yellow-outline"
              textStyle={{ color: "black" }}
              style={tw`m-1`}
            >
              <TransProp v={r?.name} />
            </BigButton>
          ))}
        </HalfModal>
      )}
    </View>
  );
};

export default AdminOrdersModule;
