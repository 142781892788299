import AntDesign from "@expo/vector-icons/AntDesign";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import { PlatformContext } from "../../contexts";
import { isAndroid, isWeb } from "../../utils/platform";
import AddressSearch from "../Forms/AddressSearch";

const SearchInput = ({
  showCenter,
  onChange,
  onCenter,
  placeholder,
  size = 53,
  style,
}) => {
  const platform = useContext(PlatformContext);
  const { t } = useTranslation();

  return (
    <View
      style={{
        ...tw`flex-1 bg-white rounded-xl shadow-md`,
        ...style,
      }}
    >
      <View>
        <AddressSearch
          placeholder={t(
            placeholder || `restaurant.find_one.${platform.vertical}`
          )}
          inputContainerStyle={{
            ...tw`w-full rounded-xl ${
              isAndroid ? "py-3" : "py-4.5"
            } pl-12 pr-3 bg-white`,
            zIndex: 99999,
          }}
          resultsContainerStyle={{
            ...tw`rounded-xl border absolute w-full top-8 pt-5 bg-white`,
            borderColor: platform.colors.yellow_main,
            zIndex: 999,
          }}
          inputStyle={{ ...tw`w-full`, ...(isWeb && { outline: "none" }) }}
          onChange={onChange}
        />
        <AntDesign
          name="search1"
          size={20}
          style={{
            position: "absolute",
            zIndex: 999999,
            top: size / 2 - 10,
            left: size / 2 - 10,
          }}
        />
      </View>
      {showCenter && (
        <TouchableOpacity
          onPress={onCenter}
          style={{
            position: "absolute",
            zIndex: 99999999,
            top: size / 2 - 7.5,
            right: size / 2 - 10,
          }}
        >
          <FontAwesome5 name="location-arrow" size={15} />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default SearchInput;
