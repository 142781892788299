import { useNavigation } from "@react-navigation/native";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import tw from "twrnc";
import Mapper from "../../../components/Mapper";
import RestaurantCard from "../../../components/RestaurantCard";
import SimpleHeader from "../../../components/SimpleHeader";
import SimpleSearch from "../../../components/SimpleSearch";
import Tabs from "../../../components/Tabs";
import { PlatformContext } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import { getRestaurants } from "../../../utils/db";
import { isDesktop } from "../../../utils/platform";

const AdminHomeModule = () => {
  const platform = useContext(PlatformContext);
  const { navigate } = useNavigation();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState(0);
  const { data, loading } = useApi(
    getRestaurants,
    {
      filter: tab === 0 ? "my-active-restaurants" : "my-inactive-restaurants",
    },
    [tab]
  );

  const Search =
    !loading && data.length > 1 ? (
      <View style={{ height: 40 }}>
        <SimpleSearch
          style={{
            ...tw`mb-0 mx-3 border`,
            borderColor: platform.colors.gray_light,
          }}
          search={search}
          setSearch={setSearch}
          placeholder={`restaurant.find_one.${platform.vertical}`}
        />
      </View>
    ) : null;

  const filteredData = data?.filter(
    (r) =>
      !search ||
      JSON.stringify(r.name).toLowerCase().includes(search.trim().toLowerCase())
  );

  return (
    <View style={{ flex: 1, ...tw`bg-white` }}>
      <SimpleHeader
        title={t(`admin.my_restaurants.${platform.vertical}`)}
        right={{
          onPress: () => navigate("admin-restaurant-create"),
          source: "plus",
        }}
        style={isDesktop && { marginTop: 25 }}
      />
      {Search}
      <Tabs
        onChange={setTab}
        tabs={[
          {
            key: "active",
            title: t("word.active_restaurants"),
            component: () => (
              <ScrollView style={tw`p-3`}>
                <Mapper
                  loading={loading}
                  data={filteredData}
                  render={(d) => (
                    <RestaurantCard
                      key={`r_${d.id}`}
                      restaurant={d}
                      onPress={() =>
                        navigate("admin-overview", { restaurant: d.id })
                      }
                    />
                  )}
                />
              </ScrollView>
            ),
          },
          {
            key: "inactive",
            title: t("word.inactive_restaurants"),
            component: () => (
              <ScrollView style={tw`p-3`}>
                <Mapper
                  loading={loading}
                  data={filteredData}
                  render={(d) => (
                    <RestaurantCard
                      key={`r_${d.id}`}
                      restaurant={d}
                      onPress={() =>
                        navigate("admin-overview", { restaurant: d.id })
                      }
                    />
                  )}
                />
              </ScrollView>
            ),
          },
        ]}
      />
    </View>
  );
};

export default AdminHomeModule;
