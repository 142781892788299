import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { webBack } from "../../utils/others";

const Redirect = ({ to }) => {
  const { goBack, navigate } = useNavigation();

  useEffect(() => {
    if (to) navigate(to);
    else webBack(goBack);
  }, []);

  return null;
};

export default Redirect;
