import Icon from "@expo/vector-icons/FontAwesome5";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageBackground, StatusBar, Text, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../components/BigButton";
import CategoryFilter from "../../components/CategoryFilter";
import ClosedRestaurant from "../../components/ClosedRestaurant";
import DesktopModal from "../../components/DesktopModal";
import Footer from "../../components/Footer";
import Loading from "../../components/Loading";
import { LanguageBubbles } from "../../components/LogoHeader";
import ProductList from "../../components/ProductList";
import RestaurantHeader from "../../components/RestaurantHeader";
import SimpleSearch from "../../components/SimpleSearch";
import { BagContext, PlatformContext, UserContext } from "../../contexts";
import useApi from "../../hooks/useApi";
import { track } from "../../utils/analytics";
import { getFile, getRestaurant, getRestaurantMenu } from "../../utils/db";
import { getAnyTrans, parseFee, parseRouteJson } from "../../utils/others";
import { isDesktop, isIos } from "../../utils/platform";
import { getCategories } from "../../utils/products";
import { styles } from "../../utils/settings";
import CheckoutModule from "../CheckoutModule/CheckoutModule";
import ProductModule from "../ProductModule/ProductModule";
import Screen from "../Screen";

const Menu = ({
  setSelectedProduct,
  restaurant,
  menu,
  search,
  loading,
  navigate,
  category,
}) => (
  <View style={tw`px-4 pt-3`}>
    <ProductList
      loading={loading}
      showAll={!!category}
      menu={menu
        ?.filter((prod) => !category || prod.category.id === category)
        .filter(
          (prod) =>
            !search ||
            search
              .trim()
              .split(" ")
              .map((keyword) =>
                JSON.stringify(prod)
                  .toLowerCase()
                  .includes(keyword.toLowerCase())
              )
              .filter((y) => y).length > 0
        )}
      restaurant={restaurant}
      onPress={(product) =>
        setSelectedProduct
          ? setSelectedProduct([product, null])
          : navigate("restaurant-product", { restaurant, menu, product })
      }
    />
  </View>
);

const MenuModule = () => {
  const platform = useContext(PlatformContext);
  const { user, lang } = useContext(UserContext);
  const [category, setCategory] = useState(null);
  const { t } = useTranslation();
  const [search, setSearch] = useState(null);
  const { params: init_restaurant } = useRoute();
  const { navigate, setOptions } = useNavigation();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { data: restaurant } = useApi(
    getRestaurant,
    {
      id: init_restaurant.id,
    },
    [init_restaurant],
    parseRouteJson(init_restaurant)
  );
  const { data: menu, loading } = useApi(
    getRestaurantMenu,
    {
      restaurant: init_restaurant.id,
    },
    [init_restaurant]
  );
  const bags = useContext(BagContext);

  useEffect(() => {
    if (restaurant?.name)
      setOptions({ title: getAnyTrans(restaurant.name, lang) });
    if (restaurant?.id)
      track("MENU_VIEW", {
        restaurant_id: restaurant.id,
        restaurant_name: getAnyTrans(restaurant.name),
      });
    setCategory(null);
  }, [restaurant]);

  useEffect(() => {
    if (search) {
      setCategory(null);
      track("MENU_SEARCH", {
        restaurant_id: restaurant.id,
      });
    }
  }, [search]);

  if (!restaurant) return <Loading />;

  const hasBack = init_restaurant?.utm_source;
  const grand_total =
    bags.totalize(restaurant.id, menu) + parseFee(restaurant.customer_fee);

  const Search = !loading ? (
    <SimpleSearch
      style={{
        ...tw`mb-0 mt-4 border`,
        borderColor: platform.colors.gray_light,
      }}
      search={search}
      setSearch={setSearch}
    />
  ) : null;

  const CategorySelect = !loading ? (
    <CategoryFilter
      categories={getCategories(menu)}
      category={category}
      setCategory={(x) => setCategory(category === x ? null : x)}
    />
  ) : null;

  if (isDesktop)
    return (
      <View
        style={{ ...tw`flex-row flex-1 bg-[${platform.colors.background}]` }}
      >
        <DesktopModal
          visible={selectedProduct}
          onClose={() => setSelectedProduct(null)}
        >
          <ProductModule
            onDone={() => setSelectedProduct(null)}
            restaurant={restaurant}
            product={selectedProduct?.[0]}
            selected={selectedProduct?.[1]}
          />
        </DesktopModal>
        <View
          style={{
            width: window.innerWidth < 1200 ? "55%" : "70%",
            paddingTop: 80,
          }}
        >
          <ImageBackground
            resizeMode="cover"
            defaultSource={platform.appLogo}
            style={tw`w-full h-65`}
            source={
              !restaurant.image_cover
                ? platform.appLogo
                : { uri: getFile(restaurant.image_cover, 1200, 100) }
            }
          />
          <View
            style={{
              paddingLeft: window.innerWidth < 1200 ? "5%" : "15%",
              paddingRight: "10%",
              backgroundColor: "white",
            }}
          >
            <RestaurantHeader
              noPb={!loading}
              showHeader={false}
              hasBack={hasBack}
              restaurant={restaurant}
              showFav
            >
              {Search}
              {CategorySelect}
            </RestaurantHeader>
          </View>
          <View
            style={{
              paddingLeft: window.innerWidth < 1200 ? "5%" : "15%",
              paddingRight: window.innerWidth < 1200 ? "5%" : "10%",
              backgroundColor: platform.colors.background,
            }}
          >
            <Menu
              restaurant={restaurant}
              menu={menu}
              search={search}
              setSearch={setSearch}
              loading={loading}
              navigate={navigate}
              setSelectedProduct={setSelectedProduct}
              category={category}
            />
          </View>
          <Footer />
        </View>
        <View
          style={{
            width: window.innerWidth < 1200 ? "45%" : "30%",
            position: "fixed",
            right: 0,
            top: 79,
            bottom: 0,
            ...tw`shadow-md`,
          }}
        >
          <CheckoutModule
            setSelectedProduct={setSelectedProduct}
            restaurant={restaurant}
            menu={menu}
          />
        </View>
      </View>
    );

  return (
    <>
      <Screen style={tw`p-0 mb-10 pb-20`} stickyHeaderIndices={[2]}>
        <StatusBar barStyle={"light-content"} />
        <RestaurantHeader
          noDots
          noPb={!loading}
          hasBack={hasBack}
          restaurant={restaurant}
          showFav
          actionChildren={
            <LanguageBubbles
              style={{ right: user ? (restaurant.slug ? 90 : 50) : 0, top: 4 }}
            />
          }
        />
        <View
          style={{
            ...tw`bg-white mb-3`,
            shadowOffset: { width: 1, height: 10 },
            shadowColor: `#000`,
            shadowOpacity: 0.05,
            shadowRadius: 8,
            elevation: 8,
          }}
        >
          <View style={tw`px-5`}>
            {Search}
            {CategorySelect}
          </View>
          {!isDesktop && (
            <ImageBackground
              source={require("../../../assets/dots.png")}
              style={{
                width: "100%",
                height: 6,
                position: "absolute",
                bottom: -3,
              }}
            />
          )}
        </View>
        {!loading && !isDesktop && !restaurant.avails_first_in ? (
          <ClosedRestaurant />
        ) : null}
        <Menu
          restaurant={restaurant}
          menu={menu}
          search={search}
          setSearch={setSearch}
          loading={loading}
          navigate={navigate}
          category={category}
        />
      </Screen>
      {restaurant.avails_first_in && (
        <View
          style={{
            ...tw`border-t border-gray-300 ${isIos ? "pb-5" : "pb-2"} pt-2 ${
              isIos ? "px-5" : "px-2"
            } bg-white`,
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          {restaurant.minimum_amount_order > 0 &&
          grand_total < restaurant.minimum_amount_order ? (
            <View style={{ ...tw`flex-row items-center justify-center mb-2` }}>
              <View
                style={{
                  ...tw`flex-col items-center justify-center mr-2`,
                  backgroundColor: platform.colors.red,
                  borderRadius: 15,
                  height: 15,
                  width: 15,
                }}
              >
                <Icon name="euro-sign" color="white" size={10} />
              </View>
              <Text
                style={{
                  ...styles.desc_s,
                  color: platform.colors.red,
                  maxWidth: isDesktop ? "90%" : "70%",
                }}
                numberOfLines={1}
              >
                {t("menu.order_min")} {restaurant.minimum_amount_order}€
              </Text>
            </View>
          ) : null}
          <BigButton
            disabled={bags.totalize(restaurant.id, menu) === 0}
            onPress={() =>
              navigate("restaurant-checkout", { restaurant, menu })
            }
          >
            {t("checkout.see_bag")}:{" "}
            {bags.totalize(restaurant.id, menu) > 0
              ? grand_total.toFixed(2)
              : 0}
            €
          </BigButton>
        </View>
      )}
    </>
  );
};

export default MenuModule;
