import { useEffect, useState } from "react";
import { getPlatform } from "../utils/db";
import { isWeb } from "../utils/platform";
import { readSafe, writeSafe } from "../utils/safe";
import { VERTICALS, logos } from "../utils/settings";

const forceVertical = null;
const usePlatform = () => {
  const [platform, setPlatform] = useState(null);
  const [vertical, setVertical] = useState(
    !isWeb
      ? VERTICALS.fritzy
      : forceVertical ||
          (window?.location?.hostname?.includes("pizzy")
            ? VERTICALS.pizzy
            : VERTICALS.fritzy)
  );

  const isPizzy = vertical === VERTICALS.pizzy;
  const appLogo = logos[vertical];
  const reverseLogo = isPizzy ? logos.fritzy : logos.pizzy;

  useEffect(() => {
    try {
      getPlatform().then(({ data }) => {
        setPlatform(data);
      });
    } catch (ex) {
      console.log(`ex`, ex);
    }
    try {
      readSafe("vertical").then((v) => {
        if (v) setVertical(v);
      });
    } catch (ex) {
      console.log(`ex`, ex);
    }
  }, []);

  useEffect(() => {
    if (vertical) writeSafe("vertical", vertical);
  }, [vertical]);

  const get = (s, def = null) => {
    if (!platform || !s) return def;

    const x = platform.find((y) => y.title === s);

    return x?.value || def;
  };

  return {
    platform,
    get,
    vertical,
    setVertical,
    isPizzy,
    appLogo,
    reverseLogo,
    name: vertical === VERTICALS.pizzy ? "Pizzy" : "Fritzy",
    domain: vertical === VERTICALS.pizzy ? "pizzy.be" : "fritzy.be",
    colors:
      vertical === VERTICALS.pizzy
        ? {
            yellow_main: "#88AA49",

            background: "#f1ebe6",
            background_dark: "#2E4332",

            gray_dark: "#6B6B6B",
            gray_light: "#D1D1D1",

            green: "#5BBA70",
            orange: "#ffac1c",
            red: "#C73D1F",
          }
        : {
            yellow_main: "#fbb336",

            background: "#f1ebe6",
            background_dark: "#232323",

            gray_dark: "#6B6B6B",
            gray_light: "#D1D1D1",

            green: "#5BBA70",
            orange: "#ffac1c",
            red: "#C4331E",
          },
  };
};

export default usePlatform;
