import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../../components/BigButton";

import { useTranslation } from "react-i18next";
import Loading from "../../../components/Loading";
import SimpleHeader from "../../../components/SimpleHeader";
import { PlatformContext } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import { getRestaurant, updateRestaurant } from "../../../utils/db";
import { webBack } from "../../../utils/others";
import { isDesktop } from "../../../utils/platform";
import { styles } from "../../../utils/settings";
import { LangField } from "../CategoriesModule/AdminCategoriesModule";
import { EditButton } from "../ProductEditModule/AdminProductEditModule";

const AdminInfoModule = () => {
  const platform = useContext(PlatformContext);
  const { goBack } = useNavigation();
  const [form, setForm] = useState({});
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();
  const {
    params: { restaurant },
  } = useRoute();
  const { data, loading } = useApi(getRestaurant, {
    id: restaurant,
    edit: true,
  });

  useEffect(() => {
    setForm(data);
  }, [data]);

  const onSubmit = async () => {
    setSaving(true);

    await updateRestaurant(restaurant, form);

    webBack(goBack);
  };

  return (
    <View style={{ flex: 1, ...tw`bg-white` }}>
      <SimpleHeader
        left={{ onPress: () => webBack(goBack) }}
        title={t(`admin.my_restaurant.${platform.vertical}`)}
        style={isDesktop && { marginTop: 25 }}
      />
      {loading && <Loading />}
      {!loading && (
        <ScrollView style={tw`p-3`} contentContainerStyle={tw`pb-20`}>
          <Text style={{ ...styles.header_lg, ...tw`mb-3` }}>
            {t("field.restaurant_name")}
          </Text>
          <LangField onChange={setForm} value={form} field="name" />
          <Text style={{ ...styles.header_lg, ...tw`mb-3` }}>
            {t("field.description")}
          </Text>
          <LangField onChange={setForm} value={form} field="description" />
          <Text style={{ ...styles.header_lg, ...tw`mb-3 mt-6` }}>
            {t("field.current_promo")}
          </Text>
          <LangField onChange={setForm} value={form} field="promo" />
          <View style={tw`flex-row items-center justify-between mb-3 mt-3`}>
            <Text style={styles.header_2xl}>{t("word.eat_in_places")}</Text>
            <EditButton
              label={t("word.add")}
              icon="plus"
              e={() =>
                setForm((f) => ({
                  ...f,
                  eat_in: [
                    ...(f.eat_in || []),
                    { id: new Date().getTime(), name: form.name },
                  ],
                }))
              }
            />
          </View>
          {form?.eat_in?.map((o, i) => (
            <View
              key={`eat_in_${i}`}
              style={tw`flex-row items-center justify-between gap-2 border-b border-gray-300 pt-3`}
            >
              <View style={tw`flex-4`}>
                <LangField
                  field="name"
                  onChange={({ name: val }) => {
                    let copy = [...form.eat_in];
                    let x = copy.find((x) => x.id === o.id);
                    if (!x) return;
                    x.name = val;
                    setForm((f) => ({ ...f, eat_in: copy }));
                  }}
                  value={o}
                />
              </View>
              <View style={tw`flex-1`}>
                <BigButton
                  type="red-outline"
                  style={tw`p-2 px-1`}
                  onPress={() => {
                    const eat_in = [...(form.eat_in || [])];
                    eat_in.splice(i, 1);
                    setForm((f) => ({ ...f, eat_in }));
                  }}
                  icon="times"
                  iconColor={platform.colors.red}
                />
              </View>
            </View>
          ))}
        </ScrollView>
      )}
      <View style={tw`bg-white border-gray-300 pb-5 pt-4 px-5 border-t`}>
        <BigButton onPress={onSubmit} disabled={saving}>
          {t("word.save")}
        </BigButton>
      </View>
    </View>
  );
};

export default AdminInfoModule;
