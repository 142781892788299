import Icon from "@expo/vector-icons/Entypo";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../../components/BigButton";

import RNDateTimePicker from "@react-native-community/datetimepicker";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../components/Checkbox";
import HalfModal from "../../../components/HalfModal";
import Loading from "../../../components/Loading";
import RadioButton from "../../../components/RadioButton";
import SimpleHeader from "../../../components/SimpleHeader";
import SoundPlayer, { playOnce, tracks } from "../../../components/SoundPlayer";
import { TransProp } from "../../../components/Trans";
import YellowLine from "../../../components/YellowLine";
import { PlatformContext } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import { parseDate } from "../../../utils/days";
import { getRestaurant, updateRestaurant } from "../../../utils/db";
import { intTime, webBack } from "../../../utils/others";
import { isAndroid, isDesktop, isIos } from "../../../utils/platform";
import settings, { styles } from "../../../utils/settings";
import { InputBase } from "../CategoriesModule/AdminCategoriesModule";
import { hours } from "../OpeningHoursModule/AdminOpeningHoursModule";
import { EditButton } from "../ProductEditModule/AdminProductEditModule";

const SpecialShifts = ({ data, onRemove, setModal }) => {
  const platform = useContext(PlatformContext);
  const { t } = useTranslation();

  return (
    <View style={tw`mt-5`}>
      <Text style={styles.header_lg}>{t("word.advanced_options")}</Text>
      {data?.map((s, ind) => (
        <View style={tw`mt-3 justify-center border-bottom`}>
          <View style={tw`flex-row items-center`}>
            <Text style={tw`mr-2`}>{t("word.on")} </Text>
            <EditButton
              label={s.days
                .map((d) => t(`day.${settings.days[d - 1]}`))
                .join(", ")}
              icon="calendar"
              e={() =>
                setModal({
                  hi: ind,
                  title: "admin.choose_days",
                  data: "days",
                  type: "days",
                  value: s.days,
                })
              }
            />
            <TouchableOpacity style={tw``} onPress={() => onRemove(ind)}>
              <Icon name="cross" color={platform.colors.red} size={20} />
            </TouchableOpacity>
          </View>
          <View style={tw`flex-row items-center my-2`}>
            <Text style={tw`mr-2`}>{t("word.accept")}</Text>
            <EditButton
              label={s.max_orders || "0"}
              e={() =>
                setModal({
                  hi: ind,
                  title: "admin.orders_per_shift",
                  data: "orders",
                  type: "max_orders",
                  value: s.max_orders || 0,
                })
              }
            />
            <Text style={tw`ml-2`}>{t("word.orders")}</Text>
          </View>
          <View style={tw`flex-row items-center`}>
            <Text style={tw`mr-2`}>{t("time.from")} </Text>
            <EditButton
              label={intTime(s.from)}
              icon="clock"
              e={() =>
                setModal({
                  hi: ind,
                  title: "admin.choose_time",
                  data: "hours",
                  type: "from",
                  value: s.from,
                })
              }
            />
            <Text style={tw`mx-2`}> {t("time.until")} </Text>
            <EditButton
              label={intTime(s.until)}
              icon="clock"
              e={() =>
                setModal({
                  hi: ind,
                  title: "admin.choose_time",
                  data: "hours",
                  type: "until",
                  value: s.until,
                })
              }
            />
          </View>
        </View>
      ))}
    </View>
  );
};

const sounds = Object.keys(tracks);

const SoundChanger = ({ form, setForm }) => {
  const { t } = useTranslation();
  const [newSound, setNewSound] = useState(null);

  const onSelect = async (sound) => {
    setForm((f) => ({ ...f, sound }));

    playOnce(sound);
  };

  return (
    <View>
      {newSound && (
        <SoundPlayer loop={false} play={newSound} track={newSound} />
      )}
      <YellowLine style={tw`my-5`} />
      <Text style={{ ...styles.header_lg }}>{t("admin.sound_order")}</Text>
      <Text style={{ ...styles.desc, ...tw`mb-3` }}>
        {t("admin.sound_order_desc")}
      </Text>
      {sounds.map((s) => (
        <TouchableOpacity
          key={`sound_${s}`}
          onPress={() => onSelect(s)}
          style={tw`flex-row justify-between items-center mb-3`}
        >
          <Text style={styles.header_me}>{s}</Text>
          <RadioButton
            selected={form?.sound === s}
            onPress={() => onSelect(s)}
          />
        </TouchableOpacity>
      ))}
    </View>
  );
};

const AdminStatusModule = () => {
  const platform = useContext(PlatformContext);
  const { goBack } = useNavigation();
  const [modal, setModal] = useState(null);
  const [form, setForm] = useState({});
  const [saving, setSaving] = useState(false);
  const [select, setSelect] = useState(false);
  const [advanced, setAdvanced] = useState(false);
  const { t } = useTranslation();
  const {
    params: { restaurant },
  } = useRoute();
  const { data, loading } = useApi(getRestaurant, {
    id: restaurant,
    edit: true,
  });

  useEffect(() => {
    setForm(data);
  }, [data]);

  const onSubmit = async () => {
    setSaving(true);

    await updateRestaurant(restaurant, form);

    webBack(goBack);
  };

  const onChangeOrdersShift = (action, field, minimum = 1) => {
    const o = form?.[field] || minimum;

    if (action === "-") {
      if (o <= minimum) return;

      return setForm({ ...form, [field]: o - 1 });
    }

    return setForm({ ...form, [field]: o + 1 });
  };

  const onChangeDelivery = (fi, v) => {
    setForm((f) => ({ ...f, delivery: { ...f.delivery, [fi]: v } }));
  };

  const isClosed =
    form?.reopen_at &&
    parseDate(form.reopen_at) > DateTime.now().setZone("utc");

  return (
    <View style={{ flex: 1, ...tw`bg-white` }}>
      <SimpleHeader
        left={{ onPress: () => webBack(goBack) }}
        title={TransProp({ v: form?.name })}
        style={isDesktop && { marginTop: 25 }}
      />
      {loading && <Loading />}
      {!loading && (
        <ScrollView style={tw`p-3`} contentContainerStyle={tw`pb-20`}>
          <Text style={{ ...styles.header_lg, ...tw`` }}>
            {t(`admin.status_restaurant.${platform.vertical}`)}
          </Text>
          <Text style={{ ...styles.desc, ...tw`mb-3` }}>
            {t("admin.status_restaurant_desc")}
          </Text>
          {!isClosed ? (
            [15, 30, 45, 60, 90, 120, 999].map((time) => (
              <BigButton
                key={`time_${time}`}
                onPress={() => {
                  if (time > 900) return setSelect(true);
                  setForm((f) => ({
                    ...f,
                    reopen_at: DateTime.now()
                      .plus({ minutes: time })
                      .setZone("utc")
                      .toISO(),
                  }));
                }}
                type="yellow-outline"
                style={tw`mt-2 py-2`}
              >
                <Text
                  style={tw`${
                    platform.isPizzy
                      ? `text-[${platform.colors.yellow_main}]`
                      : ""
                  }`}
                >
                  {time > 900 ? t("word.pick_date") : time}{" "}
                  {time < 900 ? t("word.minutes") : null}
                </Text>
              </BigButton>
            ))
          ) : (
            <View style={tw`flex-1`}>
              <Text
                style={{
                  ...styles.error_me,
                  textAlign: "center",
                  color: platform.colors.red,
                }}
              >
                {t("admin.closed_until", {
                  time: DateTime.fromISO(form?.reopen_at).toLocaleString(
                    DateTime.DATETIME_SHORT
                  ),
                })}
              </Text>
              <BigButton
                style={tw`mt-3 py-2`}
                type="yellow-outline"
                onPress={() =>
                  setForm((f) => ({
                    ...f,
                    reopen_at: null,
                  }))
                }
              >
                <Text
                  style={tw`${
                    platform.isPizzy
                      ? `text-[${platform.colors.yellow_main}]`
                      : ""
                  }`}
                >
                  {t("word.open_again")}
                </Text>
              </BigButton>
            </View>
          )}
          {select && (
            <RNDateTimePicker
              minimumDate={new Date()}
              value={new Date()}
              onChange={(a, b) => {
                if (isAndroid && a.type !== "set") return;
                setForm((f) => ({
                  ...f,
                  reopen_at: DateTime.fromJSDate(b)
                    .startOf("day")
                    .setZone("utc")
                    .toISO(),
                }));

                setSelect(null);
              }}
              mode="date"
              display={isIos ? "inline" : "calendar"}
            />
          )}
          <YellowLine style={tw`my-5`} />
          <Text style={{ ...styles.header_lg }}>
            {t("admin.orders_per_shift")}
          </Text>
          <Text style={{ ...styles.desc, ...tw`mb-3` }}>
            {t("admin.orders_per_shift_desc")}
          </Text>
          <View style={tw`flex-row items-center justify-between`}>
            <BigButton
              onPress={() => onChangeOrdersShift("-", "orders_per_shift")}
              type="yellow-outline"
              iconColor="black"
              icon="minus"
            />
            <Text>{form?.orders_per_shift || 1}</Text>
            <BigButton
              onPress={() => onChangeOrdersShift("+", "orders_per_shift")}
              type="yellow-outline"
              iconColor="black"
              icon="plus"
            />
          </View>
          {form?.special_shifts?.length > 0 && (
            <>
              {!advanced && (
                <BigButton
                  type="yellow-outline"
                  style={tw`py-2 mt-3`}
                  onPress={() => setAdvanced(true)}
                >
                  {t("word.advanced_options")}
                </BigButton>
              )}
              {advanced && (
                <>
                  <SpecialShifts
                    data={form?.special_shifts}
                    onChange={(x) => setForm({ ...form, special_shifts: x })}
                    onRemove={(ind) => {
                      const x = [...form.special_shifts];
                      x.splice(ind, 1);
                      setForm({ ...form, special_shifts: x });
                    }}
                    setModal={setModal}
                  />
                  <BigButton
                    onPress={() =>
                      setForm((f) => ({
                        ...f,
                        special_shifts: [
                          ...f.special_shifts,
                          f.special_shifts?.[0] || {
                            max_orders: 1,
                            days: [],
                            from: 0,
                            until: 1,
                          },
                        ],
                      }))
                    }
                    style={tw`py-2 mt-3`}
                  >
                    Add exception
                  </BigButton>
                </>
              )}
            </>
          )}
          {form?.time_before_order && (
            <>
              <YellowLine style={tw`my-5`} />
              <Text style={{ ...styles.header_lg }}>
                {t("admin.time_needed_per_order")}
              </Text>
              <Text style={{ ...styles.desc, ...tw`mb-3` }}>
                {t("admin.time_needed_per_order_desc")}
              </Text>
              <View style={tw`flex-row items-center justify-between`}>
                <BigButton
                  onPress={() => onChangeOrdersShift("-", "time_before_order")}
                  type="yellow-outline"
                  iconColor="black"
                  icon="minus"
                />
                <Text>
                  {form?.time_before_order || settings.time_before_order}
                </Text>
                <BigButton
                  onPress={() => onChangeOrdersShift("+", "time_before_order")}
                  type="yellow-outline"
                  iconColor="black"
                  icon="plus"
                />
              </View>
            </>
          )}
          {form?.minimum_amount_order && (
            <>
              <YellowLine style={tw`my-5`} />
              <Text style={{ ...styles.header_lg }}>
                {t("admin.min_amount_order")}
              </Text>
              <Text style={{ ...styles.desc, ...tw`mb-3` }}>
                {t("admin.min_amount_order_desc")}
              </Text>
              <View style={tw`flex-row items-center justify-between`}>
                <BigButton
                  onPress={() =>
                    onChangeOrdersShift("-", "minimum_amount_order", 0)
                  }
                  type="yellow-outline"
                  iconColor="black"
                  icon="minus"
                />
                <Text>{form?.minimum_amount_order || 0} €</Text>
                <BigButton
                  onPress={() =>
                    onChangeOrdersShift("+", "minimum_amount_order", 0)
                  }
                  type="yellow-outline"
                  iconColor="black"
                  icon="plus"
                />
              </View>
            </>
          )}
          {form?.delivery && (
            <>
              <YellowLine style={tw`my-5`} />
              <Text style={{ ...styles.header_lg }}>
                {t("admin.delivery_title")}
              </Text>
              <Text style={{ ...styles.desc, ...tw`mb-3` }}>
                {t("admin.delivery_desc")}
              </Text>
              <TouchableOpacity
                style={tw`flex-row items-center mb-3`}
                onPress={(x) =>
                  onChangeDelivery("enabled", !form.delivery.enabled)
                }
              >
                <Checkbox checked={form.delivery.enabled} />
                <Text
                  style={tw`flex-1 text-base ml-5 py-1.5 font-semibold text-black`}
                >
                  {t("admin.delivery_enabled")}
                </Text>
              </TouchableOpacity>
              <Text style={{ ...styles.header_me, ...tw`mb-1` }}>
                {t("admin.delivery_price")}
              </Text>
              <View style={tw`flex-row items-center justify-between`}>
                <BigButton
                  onPress={() =>
                    onChangeDelivery(
                      "price",
                      Math.max(form.delivery.price || 0, 1) - 1
                    )
                  }
                  type="yellow-outline"
                  iconColor="black"
                  icon="minus"
                />
                <Text>{form.delivery.price || 0} €</Text>
                <BigButton
                  onPress={() =>
                    onChangeDelivery("price", (form.delivery.price || 0) + 1)
                  }
                  type="yellow-outline"
                  iconColor="black"
                  icon="plus"
                />
              </View>
              <Text style={{ ...styles.header_me, ...tw`mb-1 mt-3` }}>
                {t("admin.delivery_free_from")}
              </Text>
              <View style={tw`flex-row items-center justify-between`}>
                <BigButton
                  onPress={() =>
                    onChangeDelivery(
                      "free_from",
                      Math.max(form.delivery.free_from || 0, 1) - 1
                    )
                  }
                  type="yellow-outline"
                  iconColor="black"
                  icon="minus"
                />
                <Text>{form.delivery.free_from || 0} €</Text>
                <BigButton
                  onPress={() =>
                    onChangeDelivery(
                      "free_from",
                      (form.delivery.free_from || 0) + 1
                    )
                  }
                  type="yellow-outline"
                  iconColor="black"
                  icon="plus"
                />
              </View>
              <View style={tw`flex-row items-center justify-between mt-3`}>
                <Text style={styles.header_me}>
                  {t("admin.delivery_postcodes")}{" "}
                </Text>
                <EditButton
                  label={t("word.add")}
                  icon="plus"
                  e={() => {
                    const postcodes = [...(form.delivery.postcodes || [])];
                    postcodes.push([...postcodes].reverse()[0] || 1000);
                    onChangeDelivery("postcodes", postcodes);
                  }}
                />
              </View>

              {form?.delivery?.postcodes?.map((o, i) => (
                <View
                  key={`postcode${i}`}
                  style={tw`flex-row items-center justify-between gap-2 border-b border-gray-300 pt-3`}
                >
                  <View style={tw`flex-4`}>
                    <InputBase
                      maxLength={5}
                      onChangeText={(v) => {
                        const postcodes = [...(form.delivery.postcodes || [])];
                        const num = parseInt(v);
                        postcodes[i] = isNaN(num) ? "" : num;
                        onChangeDelivery("postcodes", postcodes);
                      }}
                      value={o.toString()}
                      style={tw`text-black text-lg mt--1`}
                    />
                  </View>
                  <View style={tw`flex-1`}>
                    <BigButton
                      type="red-outline"
                      style={tw`p-2 px-1`}
                      onPress={() => {
                        const postcodes = [...(form.delivery.postcodes || [])];
                        postcodes.splice(i, 1);
                        onChangeDelivery("postcodes", postcodes);
                      }}
                      icon="times"
                      iconColor={platform.colors.red}
                    />
                  </View>
                </View>
              ))}
            </>
          )}
          <SoundChanger form={form} setForm={setForm} />
        </ScrollView>
      )}
      <View
        style={tw`bg-white border-gray-300 ${
          isIos ? "pb-5 pt-4 px-5" : "py-3 px-3"
        } border-t`}
      >
        <BigButton onPress={onSubmit} disabled={saving}>
          {t("word.save")}
        </BigButton>
      </View>
      {modal && (
        <HalfModal visible>
          <SimpleHeader
            hideLeft
            style={tw`mt-0 p-0 mb-5 h-auto`}
            title={t(modal.title)}
            right={{ onPress: () => setModal(null), source: "close" }}
          />
          <ScrollView
            contentContainerStyle={tw`flex-row flex-wrap justify-between w-full`}
          >
            {modal.data === "hours" ? (
              hours.map((l) => (
                <BigButton
                  onPress={() => setModal((m) => ({ ...m, value: l }))}
                  key={`hour_${l}`}
                  style={{ ...tw`py-2`, width: "31%", marginBottom: "4%" }}
                  type={modal?.value === l ? "yellow" : "yellow-outline"}
                >
                  {intTime(l)}
                </BigButton>
              ))
            ) : modal.data === "orders" ? (
              <View style={tw`flex-row items-center justify-between w-full`}>
                <BigButton
                  onPress={() =>
                    setModal((m) => ({
                      ...m,
                      value: m.value === 0 ? 0 : m.value - 1,
                    }))
                  }
                  type="yellow-outline"
                  iconColor="black"
                  icon="minus"
                />
                <Text>{modal.value || 0}</Text>
                <BigButton
                  onPress={() =>
                    setModal((m) => ({ ...m, value: m.value + 1 }))
                  }
                  type="yellow-outline"
                  iconColor="black"
                  icon="plus"
                />
              </View>
            ) : modal.data === "days" ? (
              settings.days.map((d, ind) => (
                <TouchableOpacity
                  onPress={() => {
                    const x = [...modal.value];
                    const i = ind + 1;
                    if (x.includes(i))
                      x.splice(
                        x.findIndex((y) => y === i),
                        1
                      );
                    else x.push(i);

                    setModal((m) => ({ ...m, value: x }));
                  }}
                  key={`cat_${ind}`}
                  style={tw`flex-row justify-between items-center mb-5 pr-2 w-full`}
                >
                  <Text style={styles.header_lg}>{t(`day.${d}`)}</Text>
                  <Checkbox checked={modal.value?.includes(ind + 1)} />
                </TouchableOpacity>
              ))
            ) : null}
          </ScrollView>
          <View style={tw`w-full mt-5`}>
            <BigButton
              onPress={() => {
                const x = [...form.special_shifts];
                x[modal.hi][modal.type] = modal.value;
                setForm({ ...form, special_shifts: x });
                setModal(null);
              }}
            >
              {t("word.confirm")}
            </BigButton>
          </View>
        </HalfModal>
      )}
    </View>
  );
};

export default AdminStatusModule;
