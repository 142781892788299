import Icon from "@expo/vector-icons/Entypo";
import { useNavigation } from "@react-navigation/native";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import tw from "twrnc";
import smallFriesPng from "../../../assets/small_fries.png";
import YellowLine from "../../components/YellowLine";
import { PlatformContext, UserContext } from "../../contexts";
import { languages } from "../../modules/Admin/CategoriesModule/AdminCategoriesModule";
import BigButton from "../BigButton";

export const VerticalFries = ({ style }) => {
  const platform = useContext(PlatformContext);

  return (
    <View
      style={{ position: "absolute", height: 227, left: 0, right: 0, ...style }}
    >
      <ImageBackground
        resizeMode="stretch"
        style={tw`absolute h-full bottom-0 h-65 w-30 left-0 rounded-xl overflow-hidden`}
        source={
          platform.isPizzy
            ? { uri: "https://fritzy.be/fries_vertical_pizzy.png" }
            : require("../../../assets/fries_vertical.png")
        }
      />
      <ImageBackground
        resizeMode="stretch"
        style={tw`absolute h-full bottom-0 h-65 right-0 w-30 rounded-xl overflow-hidden`}
        source={
          platform.isPizzy
            ? { uri: "https://fritzy.be/fries_vertical2_pizzy.png" }
            : require("../../../assets/fries_vertical2.png")
        }
      />
    </View>
  );
};

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const {
    user,
    signOut,
    lang,
    changeLang,
    restaurantView,
    toggleRestaurantView,
    myRestaurants,
  } = useContext(UserContext);
  const { navigate } = useNavigation();
  const { t } = useTranslation();
  const platform = useContext(PlatformContext);

  const links = [
    {
      title: t(`route.nearby.${platform.vertical}`),
      action: () => navigate("main", { screen: "nearby" }),
    },
    {
      title: t("route.about"),
      action: () => window.open(`https://${platform.domain}/${lang}/pro`),
    },
    {
      title: t("route.contact"),
      action: () => window.open(`https://${platform.domain}/${lang}/contact`),
    },
    {
      title: t(
        restaurantView
          ? `admin.customer_view`
          : `admin.restaurant_view.${platform.vertical}`
      ),
      action: toggleRestaurantView,
      hidden: !myRestaurants,
    },
  ].filter((x) => !x.hidden);

  const links2 = [
    {
      title: t(`admin.my_restaurants.${platform.vertical}`),
      action: () => navigate("admin-home"),
      hidden: !myRestaurants || !restaurantView,
    },
    {
      title: t("route.orders"),
      action: () => navigate("main", { screen: "orders" }),
    },
    {
      title: t(`account.favorite_restaurants.${platform.vertical}`),
      action: () => navigate("favorite-restaurants"),
      hidden: restaurantView,
    },
    { title: t("account.my_information"), action: () => navigate("account") },
  ].filter((x) => !x.hidden);

  const loggedIn = user?.id;

  return (
    <>
      <View
        style={{
          ...tw`bg-[${platform.colors.background_dark}] flex-row justify-between w-full py-3`,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: 80,
          paddingLeft: "10%",
          paddingRight: "10%",
          zIndex: 99,
          overflow: "hidden",
        }}
      >
        {!open && <VerticalFries />}
        <TouchableOpacity
          style={tw`flex-row items-center`}
          onPress={() => [
            navigate("main", { screen: "nearby" }),
            setOpen(false),
          ]}
        >
          <Image
            source={platform.appLogo}
            style={{ height: 55, width: 55, borderRadius: 15 }}
          />
          <Text
            style={{
              ...tw`ml-3 text-2xl text-white font-bold`,
              fontFamily: "FarsonSolid",
            }}
          >
            {platform.name.toUpperCase()}
          </Text>
        </TouchableOpacity>
        <View style={tw`flex-row items-center`}>
          {languages.map((l, i) => (
            <TouchableOpacity
              style={tw`w-15 ${i < 2 ? "border-r border-white" : ""}`}
              key={`lang_${l.lang}`}
              onPress={() => changeLang(l.lang, true)}
            >
              <Text
                style={tw`${
                  l.lang === lang ? "font-bold" : "font-light"
                } text-center text-white`}
              >
                {l.lang.toUpperCase()}
              </Text>
            </TouchableOpacity>
          ))}

          <TouchableOpacity onPress={() => setOpen(!open)}>
            <Icon name={open ? "cross" : "menu"} color={"white"} size={30} />
          </TouchableOpacity>
        </View>
      </View>
      {open && (
        <View
          style={{
            ...tw`bg-[${platform.colors.background_dark}] flex-row justify-between w-full py-3 overflow-hidden`,
            position: "fixed",
            top: 80,
            left: 0,
            right: 0,
            paddingLeft: "10%",
            paddingRight: "10%",
            zIndex: 99,
            height: 300,
          }}
        >
          <View style={tw``}>
            {links.map((l, i) => (
              <View key={`action_${i}`}>
                <TouchableOpacity
                  key={`action2_${i}`}
                  onPress={() => [l.action(), setOpen(false)]}
                  style={tw`py-4 pl-3`}
                >
                  <Text style={tw`text-2xl text-white font-semibold`}>
                    {l.title}
                  </Text>
                </TouchableOpacity>
                {i < links.length - 1 && <YellowLine />}
              </View>
            ))}
          </View>
          <View
            style={{
              ...tw`flex-1 bg-[${
                platform.isPizzy
                  ? platform.colors.red
                  : platform.colors.yellow_main
              }] absolute flex-col justify-center h-full top-0 right-0 bottom-0`,
              borderTopLeftRadius: 35,
              paddingLeft: "5%",
              paddingRight: "10%",
            }}
          >
            <View style={tw`flex-row w-full justify-end mb-5`}>
              <Image
                style={{
                  height: 25,
                  width: 19,
                  tintColor: platform.isPizzy ? "white" : "black",
                }}
                source={smallFriesPng}
              />
              <Text
                style={tw`text-right ${
                  platform.isPizzy ? "text-white" : ""
                } font-medium text-xl ml-5`}
              >
                {t("route.account")}
              </Text>
            </View>
            {loggedIn ? (
              <>
                {links2.map((l, i) => (
                  <TouchableOpacity
                    key={`action2_${i}`}
                    onPress={() => [l.action(), setOpen(false)]}
                    style={tw`py-2 pl-3`}
                  >
                    <Text
                      style={tw`text-base text-right font-light ${
                        platform.isPizzy ? "text-white" : ""
                      }`}
                    >
                      {l.title}
                    </Text>
                  </TouchableOpacity>
                ))}
                <View style={tw`flex-row w-full justify-end mt-5`}>
                  <BigButton
                    onPress={signOut}
                    type="outline-dark"
                    style={tw`w-45 py-3`}
                  >
                    {t("actions.logout")}
                  </BigButton>
                </View>
              </>
            ) : (
              <View style={{ maxWidth: 200, alignSelf: "flex-end" }}>
                <Text
                  style={tw`text-base ${
                    platform.isPizzy ? "text-white" : ""
                  } text-right font-light`}
                >
                  {t("header.create_account_desc")}
                </Text>
                <View style={tw`flex-row w-full justify-end`}>
                  <BigButton
                    type="hidden-outline"
                    onPress={() => [navigate("quick-auth"), setOpen(false)]}
                    style={tw`w-full mt-3 py-3`}
                  >
                    {t("auth.sign_in")}
                  </BigButton>
                </View>
              </View>
            )}
          </View>
        </View>
      )}
      {open && (
        <TouchableOpacity
          onPress={() => setOpen(false)}
          style={{
            ...tw`flex-1 top-0 bottom-0 left-0 right-0`,
            zIndex: 1,
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        />
      )}
    </>
  );
};

export default NavBar;
