import { useContext } from "react";
import QuickAuth from "../../components/QuickAuth";
import Redirect from "../../components/Redirect";
import { UserContext } from "../../contexts";
import { isWeb } from "../../utils/platform";

const QuickAuthModule = ({}) => {
  const { user } = useContext(UserContext);

  if (user && isWeb) return <Redirect to="/" />;

  return <QuickAuth showBack />;
};

export default QuickAuthModule;
