import Icon from "@expo/vector-icons/Entypo";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../../components/BigButton";

import { useTranslation } from "react-i18next";
import HalfModal from "../../../components/HalfModal";
import Loading from "../../../components/Loading";
import SimpleHeader from "../../../components/SimpleHeader";
import YellowLine from "../../../components/YellowLine";
import { PlatformContext } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import { getRestaurant, updateRestaurant } from "../../../utils/db";
import { intTime, webBack } from "../../../utils/others";
import { isDesktop } from "../../../utils/platform";
import settings, { styles } from "../../../utils/settings";
import { EditButton } from "../ProductEditModule/AdminProductEditModule";

export const hours = [
  0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5,
  3.75, 4, 4.25, 4.5, 4.75, 5, 5.25, 5.5, 5.75, 6, 6.25, 6.5, 6.75, 7, 7.25,
  7.5, 7.75, 8, 8.25, 8.5, 8.75, 9, 9.25, 9.5, 9.75, 10, 10.25, 10.5, 10.75, 11,
  11.25, 11.5, 11.75, 12, 12.25, 12.5, 12.75, 13, 13.25, 13.5, 13.75, 14, 14.25,
  14.5, 14.75, 15, 15.25, 15.5, 15.75, 16, 16.25, 16.5, 16.75, 17, 17.25, 17.5,
  17.75, 18, 18.25, 18.5, 18.75, 19, 19.25, 19.5, 19.75, 20, 20.25, 20.5, 20.75,
  21, 21.25, 21.5, 21.75, 22, 22.25, 22.5, 22.75, 23, 23.25, 23.5, 23.75, 24,
];

const AdminOpeningHoursModule = () => {
  const platform = useContext(PlatformContext);
  const { t } = useTranslation();
  const { goBack } = useNavigation();
  const [modal, setModal] = useState(null);
  const [days, setDays] = useState([]);
  const [saving, setSaving] = useState(false);
  const {
    params: { restaurant },
  } = useRoute();
  const { data, loading } = useApi(getRestaurant, {
    id: restaurant,
    edit: true,
  });

  useEffect(() => {
    setDays(data?.days || []);
  }, [data]);

  const onSubmit = async () => {
    setSaving(true);

    await updateRestaurant(restaurant, { days });

    webBack(goBack);
  };

  const lastHour = (day) =>
    !day ? null : day?.hours?.[day?.hours?.length - 1] || null;

  const onChange = (id, a, hid) => {
    const copy = [...days];
    if (!copy.find((x) => x.id === id)) copy.push({ id, hours: [] });

    let day = copy.find((x) => x.id === id);

    if (modal && modal.value !== undefined) {
      let hour = day.hours[modal.hi];
      if (hour) {
        hour[modal.type] = modal.value;
        if (modal.type === "from" && hour.until < hour.from) {
          hour.until = hour.from;
        }
      }
    } else if (a === "add_hours") {
      const lh = day.hours.length > 0 ? day.hours[day.hours.length - 1] : null;

      const from = lh?.until || 0;
      const until = lh?.until + 1 || 24;

      day.hours.push({
        from: from < 0 ? 0 : from,
        until: until > 24 ? 24 : until,
      });
    } else if (a === "del_hours") day.hours.splice(hid, 1);

    setModal(null);
    setDays(copy);
  };

  return (
    <View style={{ flex: 1, ...tw`bg-white` }}>
      <SimpleHeader
        left={{ onPress: () => webBack(goBack) }}
        title={t("admin.opening_hours")}
        style={isDesktop && { marginTop: 25 }}
      />
      {loading && <Loading />}
      {!loading && (
        <ScrollView style={tw`p-3`} contentContainerStyle={tw`pb-20`}>
          {settings.days.map((d, i) => {
            const id = i + 1;
            const day = (days || []).find((x) => x.id === id);
            const lh = lastHour(day);

            return (
              <View key={`day_${id}`}>
                <View style={tw`flex-row items-center justify-between`}>
                  <Text style={styles.header_lg}>{t(`day.${d}`)}</Text>
                  <EditButton
                    label={t("word.add")}
                    icon="plus"
                    style={tw`px-2 py-1`}
                    e={() => onChange(id, "add_hours")}
                    disabled={lh?.until === 24}
                  />
                </View>
                {!day?.hours?.length && (
                  <Text style={styles.header_light}>
                    {t("admin_not_open_on", { day: t(`day.${d}`) })}
                  </Text>
                )}
                {day?.hours?.map((h, hi) => (
                  <View
                    key={`day_${id}_hour_${hi}`}
                    style={tw`flex-row items-center justify-between flex-wrap mt-2`}
                  >
                    <Text style={styles.header_light}>
                      {t("time.open_from")}
                    </Text>
                    <EditButton
                      label={intTime(h.from)}
                      icon="clock"
                      e={() =>
                        setModal({ d: id, hi, type: "from", value: h.from })
                      }
                    />
                    <Text style={styles.header_light}>{t("time.until")}</Text>
                    <EditButton
                      label={intTime(h.until)}
                      icon="clock"
                      e={() =>
                        setModal({ d: id, hi, type: "until", value: h.until })
                      }
                    />
                    <TouchableOpacity
                      style={tw``}
                      onPress={() => onChange(id, "del_hours", hi)}
                    >
                      <Icon
                        name="cross"
                        color={platform.colors.red}
                        size={20}
                      />
                    </TouchableOpacity>
                  </View>
                ))}
                {i < 6 && <YellowLine style={tw`my-5`} />}
              </View>
            );
          })}
        </ScrollView>
      )}
      <View style={tw`bg-white border-gray-300 pb-5 pt-4 px-5 border-t`}>
        <BigButton onPress={onSubmit} disabled={saving}>
          {t("word.save")}
        </BigButton>
      </View>
      <HalfModal visible={!!modal}>
        <SimpleHeader
          hideLeft
          style={tw`mt-0 p-0 mb-5 h-auto`}
          title={t("admin.choose_time")}
          right={{ onPress: () => setModal(null), source: "close" }}
        />
        <ScrollView
          contentContainerStyle={tw`flex-row flex-wrap justify-between w-full`}
        >
          {hours.map((l) => (
            <BigButton
              onPress={() => setModal((m) => ({ ...m, value: l }))}
              key={`hour_${l}`}
              style={{ ...tw`py-2 px-0`, width: "24.5%", marginBottom: "1%" }}
              type={modal?.value === l ? "yellow" : "yellow-outline"}
              textStyle={{
                color: platform.isPizzy
                  ? modal?.value === l
                    ? "white"
                    : "black"
                  : undefined,
              }}
            >
              {intTime(l)}
            </BigButton>
          ))}
        </ScrollView>
        <View style={tw`w-full mt-5`}>
          <BigButton onPress={() => onChange(modal.d)}>
            {t("word.confirm")}
          </BigButton>
        </View>
      </HalfModal>
    </View>
  );
};

export default AdminOpeningHoursModule;
